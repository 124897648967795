import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';


export interface DistrictItem {
    code: string,
    display: string,
    id: number
}

export interface ApiResult {
    status: string,
    data: Array<DistrictItem>,
    length: number,
    totalRecords: number
}

export interface DistrictState {
    districts: Array<DistrictItem>
}

export const initialState: DistrictState = {
    districts: [] as Array<DistrictItem>
}


export const DistrictSlice = createSlice({
    name: "districts",
    initialState,
    reducers: {
        setDistricts: (state, action: PayloadAction<Array<DistrictItem>>) => {
            state.districts = action.payload;
        }
    }
});

export const {setDistricts} = DistrictSlice.actions;

export const selectDistricts =  (state:RootState) => state.districts.districts;

export default DistrictSlice.reducer;
