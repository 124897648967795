import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';

export interface CountyState {
    countyName: string,
    taxYear: number,
    status: 'idle' | 'loading' | 'failed';
  }
  
  const initialState: CountyState = {
    countyName: '',
    taxYear: 0,
    status: 'idle',
  };


export const countySlice = createSlice({
    name: 'county',
    initialState,
    reducers: {
        setCountyName: (state, action: PayloadAction<string>) => {
            state.countyName = action.payload;
        },
        setTaxYear: (state, action: PayloadAction<number>) => {
            state.taxYear = action.payload;
        },
        setStatus: (state, action: PayloadAction<'idle' | 'loading' | 'failed'>) => {
            state.status = action.payload;
        }
    }
});

export const {  setCountyName, setTaxYear, setStatus } = countySlice.actions;

export const selectName = (state:RootState) => state.county.countyName;
export const selectTaxYear = (state:RootState) => state.county.taxYear;
export const selectStatus = (state:RootState) => state.county.status;


export default countySlice.reducer;