import { Box, Typography } from "@mui/material"

export interface Kvp {
    name: string,
    value: any
}

export function AttributeItem(props: Kvp) {
    return (
        <Box>
            <Typography sx={{ width: '33%', flexShrink: 1, color: 'text.secondary' }}>
                {props.name}:
            </Typography>
            <Typography sx={{ width: '65%', flexShrink: 1 }}>
                {props.value}
            </Typography>
        </Box>
    )
}