import { Stack, Autocomplete, TextField, AutocompleteInputChangeReason, AutocompleteChangeReason, AutocompleteChangeDetails, FormControl } from '@mui/material';
import { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import React from 'react';
import { API_KEY } from '../../app/constants';
import { newCustomEventEmitter } from '../../app/events';


interface IasAutocompleteReqProps {
    url: string,
    label: string,
    county: string | undefined,
    taxyr: number | string | undefined,
}

interface IasAutocompleteOptProps {
    onValueChange: (value: any) => void,
    disabled: boolean | false
}

interface IasAutocompleteProps
    extends IasAutocompleteReqProps, IasAutocompleteOptProps { } { }

const defaultProps: IasAutocompleteOptProps = {
    onValueChange: (value: any) => { },
    disabled: false
}

const Typeahead = (props: IasAutocompleteProps) => {


    const emitter = newCustomEventEmitter();

    const [ElemetValue, setElementValue] = useState("");

    const value = () => {
        return ElemetValue;
    }

    const [jsonResults, setJsonResults] = useState([]);
    const baseUrl: string = `${props.url}`;

    const queryToUrl = (find: string) => { return `${baseUrl}?find=${find}&apiKey=${API_KEY}`; }

    const doQuery = (value: string) => {
        if (value) {
            fetch(queryToUrl(value), { method: "GET" })
                .then((response) => response.json())
                .then((json) => {
                    if (json.status === "OK") {
                        var b = json.data.map((obj: { val: any; }) => {
                            return obj.val
                        })
                        setJsonResults(b);
                    }
                });
        }
    }

    const handleChange = (event: React.SyntheticEvent,
        value: any,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails) => {

        if (reason === 'selectOption') {
            emitter.emit('autocomplete-selected', value)
        }
        props.onValueChange!(value)
    }

    const getResults = (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
        if (reason === 'clear' || reason === 'reset') {
            setJsonResults([]);
        }
        if (value) {
            doQuery(value)
            props.onValueChange!(value)
        }
    }

    return (
        <FormControl>
            <Stack spacing={2} flexShrink={1}>
                <Autocomplete
                    id='ias_autocomplete'
                    freeSolo
                    disabled={props.disabled}
                    getOptionLabel={(jsonResults) => jsonResults}
                    options={jsonResults}
                    sx={{ width: 300 }}
                    isOptionEqualToValue={(option, value) => option === value}
                    noOptionsText={"No results found"}
                    renderOption={(props, jsonResults) => (
                        <Box component={'li'} {...props}>
                            {jsonResults}
                        </Box>
                    )}
                    renderInput={(params) => <TextField {...params} label={props.label} />}
                    onInputChange={getResults}
                    onChange={handleChange}
                    ref={emitter}
                />
            </Stack>
        </FormControl>
    )
}

Typeahead.defaultProps = defaultProps;

export default Typeahead;