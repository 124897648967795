import { Nightlife, Photo } from '@mui/icons-material';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { act } from 'react-dom/test-utils';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState, AppThunk } from '../../app/store';
import { fetchPRCData } from './prcAPI';

export interface ApiResult {
    status: string
    data: CardsData[]
    photos: PhotoItem[]
    mapcards: MapCardItem[]
    aprval: AprvalItem[]
    asmt: AsmtItem[]
    sales: SaleItem[]
    mapOpts: MapOptItem[]
    card_numbers: number[]
}

export interface CardsData {
    card: number
    parcel: ParcelItem
    dwellings: DwellingItem[]
    obys: ObyItem[]
    comdats: ComdatItem[]
    land: LandItem[]
    addns: AddnItem[]
}

export interface ParcelItem {
    acres: number
    aprbldg: number
    aprland: number
    aprtot: number
    book: string
    careof: any
    cityname: string
    districtname: string
    dmp: string
    jur: string
    legal1: string
    legal2: string
    legal3: any
    luc: string
    nbhd: string
    class: string
    objectid: number
    own1: string
    own2: any
    owneraddr: string
    page: string
    parid: string
    situsaddr: string
    statecode: string
    taxdistrict: string
    taxmap: string
    taxparcel: string
    taxyr: number
    zip1: string
}

export interface DwellingItem {
    attic: number
    attictype: string
    bldgstyle: string
    bsmt: number
    bsmtcar: number
    bsmttype: string
    card: number
    cdu: string
    cline: number
    condolvl: number
    condotyp: number
    condovw: number
    exterior: string
    extwall: number
    finbsmtarea: number
    finbsmtl: number
    finbsmtw: number
    fixaddl: number
    fixbath: number
    fixhalf: number
    fixtot: number
    flr1area: number
    fuel: number
    fueltype: string
    grade: string
    grmunits: number
    heat: number
    heatsys: number
    heatsystem: string
    heattype: string
    intext: number
    jur: string
    mastrimarea: number
    mastriml: number
    mastrimw: number
    mgfa: number
    parid: string
    recromarea: number
    recroml: number
    recromw: number
    rembath: number
    remkit: number
    rmbed: number
    rmfam: number
    rmtot: number
    sfla: number
    stories: number
    style: number
    taxyr: number
    ufeatarea: number
    ufeatl: number
    ufeatw: number
    unfinarea: number
    unfinl: number
    unfinw: number
    unitno: number
    wbfp_o: number
    wbfp_pf: number
    wbfp_s: number
    wen: string
    yrblt: number
    yrremod: number
}

export interface ObyItem {
    adjfact: number
    adjrcn: number
    area: number
    areasum: number
    card: number
    cdpct: number
    class: any
    cline: number
    cndcmplx: number
    cndflag: number
    cond: string
    condition: any
    condolvl: number
    condovw: number
    grade: string
    jur: string
    lline: number
    parid: string
    structure: string
    taxyr: number
    yrblt: number
    yrrem: number
}

export interface ComdatItem {
    adjfact: number
    areasum: number
    bld_modelid: number
    bldgros_d: any
    bldgros_v: number
    bldgval: number
    bldnum: number
    busla: number
    calcexclude: number
    card: number
    cdu: number
    chgrsn: number
    class: number
    convbldg: number
    cubicft: number
    cur: string
    deactivat: number
    depr: number
    deprt: number
    effageovr: number
    effyr: number
    excess: number
    exmppct: number
    exmpval: number
    gfact: number
    grade: string
    hga: number
    imprname: any
    jur: string
    mktadj: number
    mktrsn: number
    msarea: number
    msperim: number
    nccalc: number
    ncoval: number
    nctot: number
    ncval: number
    newconmo: number
    newconpct: number
    note1: any
    note2: any
    numident: number
    ovrmraval: number
    ovrrcnld: number
    parid: string
    parkcover: number
    parkuncov: number
    pctcomp: number
    prodamage: number
    prodate: string
    profact: number
    proprate: number
    protype: number
    rcn: number
    rcnfact: number
    rcnld: number
    recnr: number
    rectype: string
    salekey: number
    seq: number
    status: number
    structure: number
    suppress: number
    taxyr: number
    trans_id: number
    units: number
    upd_status: number
    valmeth: number
    wen: string
    who: string
    yrblt: number
}

export interface LandItem {
    acres: number
    adjfact: number
    agflg: number
    allocpct: number
    bdecr: number
    bincr: number
    brate: number
    bsize: number
    caascard: number
    card: number
    cerrate: number
    chgrsn: number
    class: number
    code: string
    comres: string
    convland: number
    cur: string
    deactivat: number
    depfact: number
    deptbl: number
    depth: number
    excess: number
    exmppct: number
    exmpval: number
    fldfact: number
    fronact: number
    froneff: number
    hectares: number
    hga: number
    infl1: number
    infl2: number
    influ: number
    influ2: number
    jur: string
    landid: number
    lcodemod: number
    lline: number
    lmod: number
    location: number
    locfact: number
    ltype: string
    lump1: number
    lump2: number
    lumpcamod: number
    lumpcd1: number
    lumpcd2: number
    mktfact: number
    modtype: number
    nccalc: number
    ncoval: number
    nctot: number
    ncval: number
    nmod: number
    note1: number
    odecr: number
    oincr: number
    orate: number
    osize: number
    ovrdepfact: number
    ovrnbhd: number
    ovrprice: number
    parid: string
    price: number
    recnr: number
    salekey: number
    schedmod: number
    seq: number
    sf: number
    sfact: number
    sizefact: number
    smod: number
    soil: number
    soilflg: number
    soilmod: number
    sqmeter: number
    status: number
    suppress: number
    taxyr: number
    topofact: number
    trans_id: number
    udate1: number
    udate2: number
    udate3: number
    udate4: number
    udate5: number
    umod: number
    units: number
    upd_status: number
    wen: string
    who: string
    zmod: number
    zoning: number
}

export interface AddnItem {
    area: number,
    card: number,
    first: string,
    jur: string,
    lline: number,
    lower: null,
    name: string,
    parid: string,
    second: string,
    taxyr: number,
    third: string,
    color: string
}

export interface PhotoItem {
    id: number
    name: string
    taxyr: number
    url: string
}
export interface MapCardItem {
    id: number
    name: string
    url: string
}

export interface AprvalItem {
    adjbldgfact: number
    adjlandfact: number
    aprtot: number
    bldgval: number
    comincval: number
    comval: number
    dwelval: number
    jur: string
    mktland: number
    mkttotval: number
    parid: string
    taxyr: number
}

export interface AsmtItem {
    jur: string
    luc: string
    parid: string
    propclass: any
    taxyr: number
    totasm1: number
    totasm2: number
    totasm3: number
    tottax: number
    valasm1: number
    valasm2: number
    valasm3: number
}

export interface SaleItem {
    aprtot: number
    asr: number
    book: number
    oldown: any
    own1: any
    page: number
    price: number
    saledt: string
    salekey: number
    instrtyp: string,
    mktvalid: number,
    saletype: string,
    saleval: number,
    source: number
}

export interface MapOptItem {
    alias: string
    id: number
    isused: boolean
    jur: string
    lastupdated: string
    map_link: string
    name: string
    options: MapOptions
    taxyr: number
    logo_url: string
    show_photos: boolean
    show_mapcard: boolean
}

export interface MapOptions {
    taxyr: number
    prc_extra_pictures: boolean
}




const initialState = {
    prcdata: {
        status: '',
        data: {} as Array<CardsData>,
        photos: {} as Array<PhotoItem>,
        mapcards: {} as Array<MapCardItem>,
        aprval: {} as Array<AprvalItem>,
        asmt: {} as Array<AsmtItem>,
        sales: {} as Array<SaleItem>,
        mapOpts: {} as Array<MapOptItem>,
        card_numbers: []
    } as ApiResult
}

export const PRCSlice = createSlice({
    name: "prc",
    initialState,
    reducers: {
        setApiResult: (site, action: PayloadAction<ApiResult>) => {
            site.prcdata = action.payload;

        },
    }
});

export const { setApiResult } = PRCSlice.actions;

export const selectCards = (state: RootState) => state.prc.prcdata.data;
export const selectSales = (state: RootState) => state.prc.prcdata.sales;
export const selectPhotos = (state: RootState) => state.prc.prcdata.photos;
export const selectMapCards = (state: RootState) => state.prc.prcdata.mapcards;
export const selectCardNumbers = (state: RootState) => state.prc.prcdata.card_numbers;
export const selectMapOpts = (state: RootState) => state.prc.prcdata.mapOpts;
export const selectAsmt = (state: RootState) => state.prc.prcdata.asmt;

export const getPRCAsync =
    (jur: string, taxyr: number, parid: string): AppThunk =>
        (dispatch, getState) => {
            fetchPRCData(jur, taxyr, parid).then(tables => {
                setApiResult(tables);
            });
        }

export const getCards =
    (jur: string, taxyr: number, parid: string): AppThunk =>
        (dispatch, getState) => {
            getPRCAsync(jur, taxyr, parid)
        }

export default PRCSlice.reducer;


