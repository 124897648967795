import { Grid } from "@mui/material"
import { currencyFormatter } from "../app/hooks"
import { ParcelItem } from "../features/prc/prcSlice"
import { AttributeItem } from "./attributeItem"

export interface ParcelDetailProps {
    parcel: ParcelItem
}

export const ParcelDetail = (props: ParcelDetailProps) => {

    return (

        <Grid container columns={{ xs: 1, sm: 4, md: 8 }} rowSpacing={6} columnSpacing={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={1} sm={2} md={4}>
                <AttributeItem name="Situs Addr." value={props.parcel.situsaddr} />
            </Grid>
            <Grid item xs={1} sm={2} md={4}>
                <AttributeItem name="Acres" value={props.parcel.acres} />
            </Grid>

            <Grid item xs={1} sm={2} md={4}>
                <AttributeItem name="Appraised Value" value={currencyFormatter(props.parcel.aprtot)} />
            </Grid>

            <Grid item xs={1} sm={2} md={4}>
                <AttributeItem name="Building Value" value={currencyFormatter(props.parcel.aprbldg)} />
            </Grid>
            <Grid item xs={1} sm={2} md={4}>
                <AttributeItem name="District" value={props.parcel.districtname} />
            </Grid>
            <Grid item xs={1} sm={2} md={4}>
                <AttributeItem name='Neighborhood' value={props.parcel.nbhd} />
            </Grid>
            <Grid item xs={1} sm={2} md={4}>
                <AttributeItem name='Land Use' value={props.parcel.luc} />
            </Grid>
        </Grid>
    )
}