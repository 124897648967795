import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { fetchParcels } from './parcelsAPI';
import { ParcelItem } from '../parcel/parcelSlice';


export interface ApiResult {
    status: string,
    data: Array<ParcelItem>,
    length: number,
    totalRecords: number,
    next_page_id?: number
}

export interface ParcelsState {
    parcels: Array<ParcelItem>
    where: string,
    orderBy: string
}

export const initialState: ParcelsState = {
    parcels:[],
    where: '',
    orderBy: ''
}

export const ParcelsSlice = createSlice({
    name: 'parcels',
    initialState,
    reducers: {
        setParcels: (site, action: PayloadAction<ApiResult>) => {
            site.parcels = action.payload!.data!;
        },
        setWhere: (site, action: PayloadAction<string>) => {
            site.where = action.payload!;
        },
        setOrderBy: (site,action: PayloadAction<string>) => {
            site.orderBy = action.payload!;
        }
    }
});



export const {setParcels, setWhere, setOrderBy} = ParcelsSlice.actions;

export const selectParcels= (state:RootState) => state.parcels.parcels;
export const selectParid = (state:RootState) => state.parcels.where;
export const selectOrderBy = (state:RootState) => state.parcels.orderBy;



export const getParcelsAsync =
(jur: string, taxyr: number, where: string): AppThunk =>
(dispatch, getState) => {
  let taxyr = getState().county.taxYear;
  let jur = getState().county.countyName;
  let where = getState().parcels.where;
  
  fetchParcels(jur,taxyr,where,10).then(data=>{
      dispatch(setParcels(data));
  })
};


export default ParcelsSlice.reducer;