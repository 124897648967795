import { Divider, FormGroup, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import '../App.css';
import { dmp2Parid, useAppDispatch, useAppSelector } from '../app/hooks';
import Typeahead from '../features/typeahead/Typeahead';

import {
    selectName, setCountyName, setTaxYear
} from '../features/county/countySlice';
import { API_BASE_URL } from '../app/constants';
import { useEffect, useState } from 'react';
import { useCustomEventListener } from '../app/events';
import { Sales } from '../features/sales/Sales';
import { SalesBookPage } from '../features/SalesBookPage/SalesBookPage';


/*
function YearToNumber(value: string | undefined) {
    if (value == undefined) {
        return 0;
    } else {
        return +value
    }
}*/

export function SalesSearch() {

    const listenerOwn1 = useCustomEventListener('autocomplete-selected', (data) => {

    });

    const { countyName, taxYear } = useParams()


    const dispatch = useAppDispatch()

    const [parcelWhere, setParcelWhere] = useState('');
    const [dmpValue, setDmpValue] = useState('');
    const [parcelValue, setParcelValue] = useState('');
    const [bookPage, setBookPage] = useState(['', '']);
    const [oldOwner, setOldOwner] = useState('');
    const [owner, setOwner] = useState('')


    const countyName1 = useAppSelector(selectName);
    if (countyName !== countyName1 && countyName !== undefined && taxYear !== undefined) {
        dispatch(setCountyName(countyName))
        dispatch(setTaxYear(+taxYear))
    }

    function dmpDisabled() {
        if (parcelValue) {
            return parcelValue.length > 1;
        } else {
            return false
        }
    }

    function paridDisabled() {
        if (dmpValue) {
            return dmpValue.length > 1;
        } else {
            return false
        }
    }

    useEffect(() => {
        let wheres = [];
        if (owner && owner.length > 0) {
            wheres.push(`own1 lk ${owner}`)
        }
        if (oldOwner && oldOwner.length > 1) {
            wheres.push(`oldown lk ${oldOwner}`)
        }

        if (bookPage.length > 0) {
            if (bookPage[0] != null && bookPage[0]!.length > 0) {
                wheres.push(`book eq ${bookPage[0]}`)
            }
            if (bookPage.length > 1 && bookPage[1] != null && bookPage[1]!.length > 0) {
                wheres.push(`page eq ${bookPage[1]}`)
            }
        }
        if (parcelValue != null && parcelValue!.length > 0) {
            wheres.push(`parid eq '${parcelValue}'`)
        }
        if (dmpValue != null && dmpValue.length > 0) {
            let parid = dmp2Parid(dmpValue)
            wheres.push(`parid eq '${parid}'`)
        }


        let where = wheres.join(' and ');

        setParcelWhere(where);


    }, [owner, bookPage, parcelValue, dmpValue, oldOwner])

    return (
        <div>
            <FormGroup ref={listenerOwn1}>
                <Grid container sx={{ maxHeight: '70%' }} columns={{ xs: 1, sm: 4, md: 8 }} rowSpacing={2} columnSpacing={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item >
                        <Typeahead
                            url={`${API_BASE_URL}/typeahead/${countyName}/${taxYear}/sales/parid`}
                            label='Parcel ID'
                            county={countyName}
                            taxyr={taxYear}
                            onValueChange={setParcelValue}
                            disabled={paridDisabled()}
                        />

                    </Grid>
                    <Grid item >
                        <Typeahead
                            url={`${API_BASE_URL}/typeahead/${countyName}/${taxYear}/parcel/dmp`}
                            label='District Map Parcel'
                            county={countyName}
                            taxyr={taxYear}
                            onValueChange={setDmpValue}
                            disabled={dmpDisabled()}
                        />
                    </Grid>


                    <Grid item >
                        <Typeahead
                            url={`${API_BASE_URL}/typeahead/${countyName}/${taxYear}/sales/oldown`}
                            label='Seller'
                            county={countyName}
                            taxyr={taxYear}
                            onValueChange={setOldOwner}
                        />
                    </Grid>
                    <Grid item >
                        <Typeahead
                            url={`${API_BASE_URL}/typeahead/${countyName}/${taxYear}/sales/own1`}
                            label='Buyer'
                            county={countyName}
                            taxyr={taxYear}
                            onValueChange={setOwner}
                        />
                    </Grid>

                    <Grid item>
                        <SalesBookPage
                            onBookPageChange={setBookPage} />
                    </Grid>
                </Grid>
            </FormGroup>
            <Divider />
            <Sales where={parcelWhere} limit={50} />
        </div>
    )
}