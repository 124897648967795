import { API_BASE_URL, API_KEY } from "../../app/constants";

export function fetchOwn1Values(countyName: string, taxYear: number, own1: string) {

    let url = `${API_BASE_URL}/typeahead/${countyName}/${taxYear}/parcel/own1?find=${own1}&apiKey=${API_KEY}`;
    return new Promise<Array<string>>((resolve, reject) => {
        fetch(url, { method: "GET" })
            .then((response) => response.json())
            .then((json) => {
                if (json.status === "OK") {
                    var b = json.data.map((obj: { val: any; }) => {
                        return obj.val
                    });
                    resolve(b);
                }
            }).catch(() => {
                resolve([]);
            });
    });

}

export const fetchOwn2Values = (countyName: string, taxYear: number, own1: string, own2: string) => {

    let url = `${API_BASE_URL}/query/${countyName}/${taxYear}/parcel?apiKey=${API_KEY}&returnFields=distinct own2 as val`;
    if (own1 !== null && own1 !== undefined && own1 !== ''){
        url += `&where=own1 lk ${own1} and own2 lk ${own2}`;
    }else {
        url += `&where=own2 lk ${own2}`;
    }

    return new Promise<Array<string>>((resolve, reject) => {
        fetch(url, { method: "GET" })
            .then((response) => response.json())
            .then((json) => {
                if (json.status === "OK") {
                    var b = json.data.map((obj: { val: any; }) => {
                        return obj.val
                    });
                    resolve(b);
                }
            }).catch(() => {
                resolve([]);
            });
    });
}

