import './image-gallery.css'
import * as React from 'react';
import { PhotoSizeSelectActual } from "@mui/icons-material";
import { AppBar, Box, Dialog, IconButton, ImageList, ImageListItem, ImageListItemBar, Slide, Toolbar, Typography } from "@mui/material";
import { useWindowDimensions } from "../app/hooks";
import { PhotoItem } from "../features/prc/prcSlice";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from '@mui/material/transitions';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface PhotoDetailProps {
    photos: Array<PhotoItem>
}

export interface GalleryItem {
    original: string
    thumbnail: string
}

export const SitePhotosGallery= (props: PhotoDetailProps) => {

    const { height, width } = useWindowDimensions();
    const imgWidth = Math.floor((width * 0.5) / 2);

    const [openDialog, setOpenDialog] = React.useState(false)
    const [currentPhoto, setCurrentPhoto] = React.useState({} as PhotoItem)
    const [photosArray, setPhotosArray] = React.useState<Array<ReactImageGalleryItem>>([] as ReactImageGalleryItem[])

    const openPhoto = (photo: PhotoItem) => {
        setCurrentPhoto(photo)
        setOpenDialog(true);
    }

    const handleClickOpen = () => {
        setOpenDialog(true);
    }
    const handleClose = () => {
        setOpenDialog(false);
    }

  

    React.useEffect(()=>{
        let photoitems = props.photos.map(photo => {
            return {
                original: `${photo.url}`,
                thumbnail: `${photo.url}?width=${imgWidth}`,
                description: photo.name,
                originalTitle: photo.name
            }
        });
        setPhotosArray(photoitems)
    },[width])

    return (
        <div>
            <ImageGallery items={photosArray}/>
        </div>
    )
}