import '../App.css';
import { Sketch } from '../features/sketch/Sketch';
import MainLayout from './mainLayoutView';
import { useParams } from 'react-router-dom';


import './views.css';
import { useAppSelector, useAppDispatch, dmp2Parid } from '../app/hooks';
import {
    selectName,
    //selectTaxYear,
    setCountyName,
    setTaxYear
} from '../features/county/countySlice'
import { Box } from '@mui/system';

export function SketchView(){

    const dispatch = useAppDispatch()
  
    const { countyName, taxYear, parid } = useParams()


    const parid1 = dmp2Parid(`${parid}`);
  
    const countyName1  = useAppSelector(selectName);
    if(countyName !== countyName1 && countyName !== undefined && taxYear !== undefined) {
       dispatch(setCountyName(countyName))
       dispatch(setTaxYear(+taxYear))
    }
    return (
      <div className="App">
        <MainLayout>
        <Box sx={{minWidth: "200px", minHeight: "400px", width: "100vw", height: "90vh"}}>
          <Sketch {...{parid:parid1,county:countyName, taxyr: taxYear, card: undefined}}></Sketch>
        </Box>
        </MainLayout>
      </div>
    );
  }