import { API_KEY, API_BASE_URL } from "../../app/constants";
import { ApiResult } from "./prcSlice";


export const fetchPRCData = (countyName: string, taxYear: number, parid: string) => {
    let url = `${API_BASE_URL}/prc/${countyName}/${taxYear}/${parid}?apiKey=${API_KEY}`;

    return new Promise<ApiResult>((resolve, reject) => {
        fetch(url).then(response => {
            response.json().then(data => {
                resolve(data);
            }
            ).catch(err => { reject(err) })
        }).catch(err => { reject(err) })
    })
}