import {Stack, Autocomplete, TextField, AutocompleteChangeReason} from '@mui/material'
import { useState, useEffect } from 'react'
import {Box} from '@mui/system'
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
    setCountyName,
    setTaxYear,
    selectName,
    selectTaxYear
} from './countySlice'

import {API_KEY} from '../../app/constants';

type countiesInfo = {
    alias: string,
    name: string,
    jur: string,
    options: {},
    taxyr: number

}

type countiesProps = {
    url: string
}


export default function CountiesAutocomplete(props: countiesProps) {

    const navigate = useNavigate();

    const countyName  = useAppSelector(selectName);
    const taxYear = useAppSelector(selectTaxYear);

    const dispatch = useAppDispatch();

    const ci:countiesInfo = {name:"", jur:"", taxyr:0, alias: "", options:{}}
    const cis:Array<countiesInfo>=[ci]
    const [jsonResults, setJsonResults] = useState<Array<countiesInfo>>(cis);
    const baseUrl:string = `${props.url}`; 

    const doQuery = () => {
        fetch(`${baseUrl}?apiKey=${API_KEY}`, {method: "GET"})
            .then((response) => response.json())
            .then((json) => {
                if(json.status == "OK") {
                    json.data.map((county: { alias: string; name: string; jur: string; options: any; taxyr: number; },i: any)=>{
                        var ci: countiesInfo = {
                            alias: county.alias,
                            name: county.name,
                            jur: county.jur,
                            options: county.options,
                            taxyr: county.taxyr
                        }
                        return ci
                    })
                setJsonResults(json.data);
            }
        });
    }

    const countySelected = (event: React.SyntheticEvent, value: countiesInfo | null, reason:AutocompleteChangeReason) => {
        if(reason == 'clear' || reason == 'blur'){
            return;
        }
        if(value == null){
            return
        }
        dispatch(setCountyName(value.name));
        dispatch(setTaxYear(value.taxyr));
        navigate(`/search/${value.name}/${value?.taxyr}`)
    }

    useEffect(()=>{
        doQuery()
    },[])


    return (
        <div>
             <Stack spacing={2} width='250px'>
             <Autocomplete
             id='ias_autocomplete'
             getOptionLabel={(jsonResult:countiesInfo)=>`${jsonResult.name} ${jsonResult.taxyr}`}
             options={jsonResults.sort((a,b)=>-b.name.localeCompare(a.name))}
             groupBy={(option:countiesInfo)=>option.name}
             sx={{width:300}}
             isOptionEqualToValue={(option,value)=>option===value}
             noOptionsText={"No results found"}
             renderOption={(props, jsonResults) => (
                 <Box component={'li'} {...props}>
                     {`${jsonResults.name} ${jsonResults.taxyr}`}
                 </Box>
             )}
             renderInput={(params)=> <TextField {...params} label={`${jsonResults[0].name} ${jsonResults[0].taxyr}`}/>}
             onChange={countySelected}
             />
         </Stack>
        </div>
    )
}