import { API_BASE_URL, API_KEY } from "../../app/constants";
import { CountiesState } from "./countiesSlice"

const baseURL = `${API_BASE_URL}/query/api_counties?apiKey=${API_KEY}`;
const updateURL = `${API_BASE_URL}/counties?apiKey=${API_KEY}`;

export function fetchCounties() {
    return new Promise<CountiesState>((resolve, reject) => {
        fetch(baseURL)
            .then((response) => response.json())
            .then((json) => {
                resolve(json);
            }).catch((err) => {
                reject(err)
            }).catch((err) => {
                reject(err);
            });
    })
}

export function updateCounty(updateObj: object) {
    return new Promise<boolean>((resolve,reject)=>{
        fetch(updateURL, {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(updateObj)
        }).then(respn=>{
            respn.json().then(obj=>{
                if (obj.status == 'OK') {
                    resolve(obj)
                }else {
                    reject(obj)
                }
            })
        })
    })
}
