import { API_KEY, API_BASE_URL } from "../../app/constants";
import { ApiResult } from './mapcardSlice';

export function fetchPhotos(jur: string, parid: string) {
    let url = `${API_BASE_URL}/mapcard/${jur}/${parid}?apiKey=${API_KEY}`;

    let Data = {
        status: "OK",
        data: [],
        length: 0,
        totalRecords: 0
    }

    return new Promise<ApiResult>((resolve, reject) => {
        fetch(url, { headers: { 'xc-auth': API_KEY } }).then((response) => {
            response.json().then(Jdata => {
                Data.status = Jdata!.status!;
                Data.length = Jdata!.length!;
                Data.totalRecords = Jdata!.totalRecords!;
                Data.data = Jdata!.data!;

                resolve(Data);

            }).catch((err) => {
                console.log(err);
                Data.status = "ERROR";
                reject(Data);
            })
        }).catch((err) => {
            console.log(err);
            Data.status = "ERROR";
            reject(Data);
        })
    })
}