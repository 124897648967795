import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

export interface DwellingApiResult {
    status: string
    data: DwellingItem[]
    length: number
    totalRecords: number,
    next_page_id: number
  }
  
  export interface DwellingItem {
    acres: number
    aprbldg: number
    aprland: number
    aprtot: number
    attictype: string
    bldgstyle: string
    book: string
    bsmt: number
    bsmtcar: number
    bsmttype: string
    card: number
    careof?: string
    cityname: string
    cline: number
    condolvl: number
    condotyp: number
    condovw: number
    districtname: string
    dmp: string
    exterior: string
    finbsmtarea: number
    fixbath: number
    fixhalf: number
    grade: string
    heatsystem: string
    heattype: string
    jur: string
    legal1: string
    legal2?: string
    legal3?: string
    luc: string
    nbhd: string
    objectid: number
    own1: string
    own2?: string
    owneraddr: string
    page: string
    parid: string
    rmbed: number
    rmfam: number
    rmtot: number
    sfla: number
    situsaddr: string
    statecode: string
    stories: number
    taxdistrict: string
    taxmap: string
    taxparcel: string
    taxyr: number
    yrblt: number
    zip1: string
  }

  export interface DwellingsState {
    dwellings: Array<DwellingItem>
    where: string,
    orderBy: string
}

export const initialState: DwellingsState = {
    dwellings:[],
    where: '',
    orderBy: ''
}
  

  export const DwellingsSlice = createSlice({
    name: 'dwellings',
    initialState,
    reducers: {
        setDwellings: (site, action: PayloadAction<Array<DwellingItem>>) => {
            site.dwellings = action.payload;
        },
        setWhere: (site, action: PayloadAction<string>) => {
            site.where = action.payload!;
        },
        setOrderBy: (site,action: PayloadAction<string>) => {
            site.orderBy = action.payload!;
        }
    }
});

export const {setDwellings, setWhere, setOrderBy} = DwellingsSlice.actions;

export const selectParcels= (state:RootState) => state.dwellings.dwellings;
export const selectParid = (state:RootState) => state.dwellings.where;
export const selectOrderBy = (state:RootState) => state.dwellings.orderBy;

export default DwellingsSlice.reducer;