import { Grid } from "@mui/material"
import { ParcelItem } from "../features/prc/prcSlice"
import { AttributeItem } from "./attributeItem"


export interface OwnerDetailProps {
    parcel: ParcelItem
}

export const OwnerDetail = (props: OwnerDetailProps) => {

    return (
        <Grid container columns={{ xs: 1, sm: 4, md: 8 }} rowSpacing={6} columnSpacing={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={1} sm={2} md={4}>
                <AttributeItem name="Own1" value={props.parcel.own1} />
            </Grid>
            <Grid item xs={1} sm={2} md={4}>
                <AttributeItem name="Owner Addr." value={`${props.parcel.owneraddr} ${props.parcel.cityname},${props.parcel.statecode} ${props.parcel.zip1}`} />
            </Grid>

            <Grid item xs={1} sm={2} md={4}>
                <AttributeItem name="Own2" value={props.parcel.own2} />
            </Grid>

            <Grid item xs={1} sm={2} md={4}>
                <AttributeItem name="Careof" value={props.parcel.careof} />
            </Grid>

            <Grid item xs={1} sm={2} md={4}>
                <AttributeItem name="Book/Page" value={`${props.parcel.book}/${props.parcel.page}`} />
            </Grid>
            <Grid item xs={1} sm={2} md={4}></Grid>
            <Grid item xs={1} sm={2} md={4}></Grid>
        </Grid>
    )
}