import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { useState, useEffect } from 'react';
import { SitePhotoItem } from '../features/sitephoto/sitePhotoSlice';
import { MapCardItem, ParcelItem, PhotoItem } from '../features/prc/prcSlice';
import { COUNTY_LIST } from './constants';
import moment from 'moment';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


let rjust = (value: string, width: number, padding?: string) => {
  padding = padding || " ";
  padding = padding.slice(0, 1);

  if (value.length < width) {
    return padding.repeat(width - value.length) + value;
  } else {
    return value;
  }
}

function getParid(d: string, m: string, p: string) {
  let subparcel = "";
  let parts = p.split(/\./g);
  let parcel = parts[0]

  if (parts.length > 1) {
    subparcel = parts[1];
  }

  return rjust(d, 2, '0') + rjust(m, 4) + rjust(parcel, 4, '0') + rjust(subparcel, 4, '0') + '0000';
}

export const dmp2Parid = (dmp: string) => {
  if (dmp.indexOf('-') < 1) {
    return dmp;
  }
  let parts = dmp.split(/-/g);
  if (parts.length < 3) {
    return dmp;
  } else {
    return getParid(parts[0], parts[1], parts[2]);
  }
}

export const currencyFormatter = (value: number) => {
  if (value === undefined) value = 0.0
  let svalue = value.toFixed(2);
  const [currency, decimal] = svalue.split('.')
  return `$${currency.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}.${decimal}`;
}

export const dateFormatter = (value: string) => {
  if (value === undefined || value === null) return ""
  const theDate = moment(value).format("MMM Do YYYY");
  return theDate;
}

function getValueByKeyName(item: Object, key: string | undefined): any {
  if (key !== undefined) {
    let k: keyof typeof item
    for (k in item) {
      if (k.toLowerCase() === key.toLowerCase()) {
        console.log(k, key)
        return item[k]
      }
    }
  }
  return ""
}

export const mapLinkGenerator = (inMapFormat: string, parcel: ParcelItem) => {
  let parts = inMapFormat.split(/{.+}/)
  let link = ""

  if (parts.length > 0) {
    link = parts[0]
    let match = inMapFormat.match(/{.+}/)?.[0]
    let prop = match?.replace('}', '').replace('{', '')
    let val = getValueByKeyName(parcel, prop)
    return `${link}${val}`
  }
  return ""
}


export const getCountyNameCode = (value: string) => {
  let valueL = value.toLowerCase();

  if (COUNTY_LIST.has(valueL)) {
    return [value, COUNTY_LIST.get(valueL)]
  }

  for (let kvp of COUNTY_LIST.entries()) {
    if (valueL === kvp[1]) {
      return kvp
    }
  }

  return []

}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


export const useSitePhotoModal = () => {

  const [selected, setSelected] = useState<PhotoItem>();
  const [isShowing, setIsShowing] = useState<boolean>(false);

  const open = (dimension: PhotoItem) => {
    setSelected(dimension);
    setIsShowing(true);
  };

  const close = () => {
    setSelected({
      id: -1,
      name: '',
      taxyr: 0,
      url: ''
    });
    setIsShowing(false);
  };

  return {
    isShowing,
    open,
    close,
    selected
  };
}

export const useMapCardModal = () => {
  const [selected, setSelected] = useState<MapCardItem>();
  const [isShowing, setIsShowing] = useState<boolean>(false);

  const open = (dimension: MapCardItem) => {
    setSelected(dimension);
    setIsShowing(true);
  };

  const close = () => {
    setSelected({
    
      id: -1,
      name: '',
      url: ''
    });
    setIsShowing(false);
  };

  return {
    isShowing,
    open,
    close,
    selected
  };

}

export const useModal = () => {
  // store which has been selected
  const [selected, setSelected] = useState<SitePhotoItem>();
  const [isShowing, setIsShowing] = useState<boolean>(false);

  const open = (dimension: SitePhotoItem) => {
    setSelected(dimension);
    setIsShowing(true);
  };

  const close = () => {
    setSelected({
      dmp: '',
      id: -1,
      jur: '',
      name: '',
      parid: '',
      suffix: '',
      taxyr: 0,
      url: ''
    });
    setIsShowing(false);
  };

  return {
    isShowing,
    open,
    close,
    selected
  };
};