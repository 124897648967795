// http://localhost:8080/v1
export const API_HOSTNAME =  'api.wvias.io'
export const API_BASE_URL = `https://${API_HOSTNAME}/v1` 
//export const API_BASE_URL = `http://127.0.0.1:8080/v1`
export const API_KEY = 'aw0YHOt4EUTZb0gQkP0='

export const COUNTY_LIST = new Map<string,string>([
    ["barbour", "01"],
    ["berkeley", "02"],
    ["boone", "03"],
    ["braxton", "04"],
    ["brooke", "05"],
    ["cabell", "06"],
    ["calhoun", "07"],
    ["clay", "08"],
    ["doddridge", "09"],
    ["fayette", "10"],
    ["gilmer", "11"],
    ["grant", "12"],
    ["greenbrier", "13"],
    ["hampshire", "14"],
    ["hancock", "15"],
    ["hardy", "16"],
    ["harrison", "17"],
    ["jackson", "18"],
    ["jefferson", "19"],
    ["kanawha", "20"],
    ["lewis", "21"],
    ["lincoln", "22"],
    ["logan", "23"],
    ["marion", "24"],
    ["marshall", "25"],
    ["mason", "26"],
    ["mcdowell", "27"],
    ["mercer", "28"],
    ["mineral", "29"],
    ["mingo", "30"],
    ["monongalia", "31"],
    ["mon", "31"],
    ["monroe", "32"],
    ["morgan", "33"],
    ["nicholas", "34"],
    ["ohio", "35"],
    ["pendleton", "36"],
    ["pleasants", "37"],
    ["pocahontas", "38"],
    ["preston", "39"],
    ["putnam", "40"],
    ["raleigh", "41"],
    ["randolph", "42"],
    ["ritchie", "43"],
    ["roane", "44"],
    ["summers", "45"],
    ["taylor", "46"],
    ["tucker", "47"],
    ["tyler", "48"],
    ["upshur", "49"],
    ["wayne", "50"],
    ["webster", "51"],
    ["wetzel", "52"],
    ["wirt", "53"],
    ["wood", "54"],
    ["wyoming", "55"]
]);