import { API_BASE_URL } from "../../app/constants"
import { BldgApiResult } from "./bldgstylesSlice";


export const fetchBldgStyles = (jur: string, taxyr: number) => {
    let url = `${API_BASE_URL}/query/${jur}/${taxyr}/dwellsearch?apiKey=aw0YHOt4EUTZb0gQkP0=&returnFields=distinct bldgstyle`;

    return new Promise<Array<string>>((resolve, reject) => {
        fetch(url).then(response => {
            response.json().then(jsonData => {
                let apiRes = jsonData as BldgApiResult;
                let vals = [] as Array<string>;
                if (apiRes.data && apiRes.data.length > 0) {
                    apiRes.data.map(style => {
                        if (style.bldgstyle != undefined) {
                            vals.push(style.bldgstyle)
                        }
                    });
                }
                resolve(vals)
            }).catch(err => {
                resolve([] as Array<string>);
            }
            )
        }).catch(err => {
            resolve([] as Array<string>)
        })
    })
}