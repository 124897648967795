import '../../App.css';
import { useWindowDimensions, useModal } from '../../app/hooks';
import { fetchPhotos } from './sitePhotoAPI';
import Box from '@mui/material/Box';

import {
    SitePhotoItem
} from './sitePhotoSlice'

import { IconButton, ImageList, ImageListItem, ImageListItemBar, Modal, Button } from '@mui/material';
import { useState, useEffect } from 'react'
import { PhotoSizeSelectActual } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import 'react-medium-image-zoom/dist/styles.css'


const style = {
    position: 'absolute' as 'absolute',
    width: '100%',
    height: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export interface SitePhotoProps {
    countyName: string | undefined,
    taxYear: number | undefined,
    parid: string | undefined
}



export function SitePhotos(props: SitePhotoProps) {



    const [photoList, setPhotoList] = useState(Array<SitePhotoItem>);
    const { height, width } = useWindowDimensions();

    function doQuery() {
        if (props.countyName !== undefined && props.taxYear !== undefined && props.parid !== undefined) {
            fetchPhotos(props.countyName, props.taxYear, props.parid).then(photoResult => {
                setPhotoList(photoResult.data);
            }).catch(() => {
                return;
            });
        }
    }

    const imgWidth = Math.floor((width * 0.9) / 2);
    const cols = width > 900 ? 2 : 1;
    const bigImageWidth = imgWidth * 2;

    const { isShowing, open, close, selected } = useModal();

    useEffect(() => {
        doQuery();
    }, [props.countyName, props.taxYear, props.parid]);

    return (
        <div>
            <ImageList cols={cols}>
                {photoList.map(pi => {
                    return <ImageListItem key={`${pi.id}`}>
                        <img src={`${pi.url}`} loading="lazy" />
                        <ImageListItemBar onClick={() => open(pi)} title={`${pi.taxyr} - ${pi.name}`} position="top" actionIcon={
                            <IconButton>
                                <PhotoSizeSelectActual sx={{ color: `white` }} />
                            </IconButton>
                        } />
                    </ImageListItem>
                })}
            </ImageList>
            <Modal
                open={isShowing}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Button style={{ float: 'right' }} onClick={close}><CloseIcon /></Button>
                    <div style={{ height: '90%', width: '90%' }}>
                    <ReactPanZoom
                        image={`${selected?.url}`}
                        alt={selected?.name}
                    />
                    </div>
                    
                </Box>
            </Modal>

        </div>
    )
}