import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';

export interface ParcelItem {
    acres: number,
    aprbldg: number,
    aprland: number,
    aprtot: number,
    book: string,
    careo: string,
    cityname: string,
    districtname: string,
    dmp: string,
    jur: string,
    legal1: string,
    legal2: string,
    legal3: string,
    nbhd: number,
    objectid: number,
    own1: string,
    own2: string,
    owneraddr: string,
    page: string,
    parid: string,
    situsaddr: string,
    statecode: string,
    taxdistrict: string,
    taxmap: string,
    taxparcel: string,
    taxy: number,
    zip1: string,
    luc: string
}

export interface ParcelState{
    parcel: ParcelItem
}

export const initialState: ParcelState = {
    parcel: {} as ParcelItem
}

export const ParcelSlice = createSlice({
    name: 'parcel',
    initialState,
    reducers: {
        setParcel: (site, action: PayloadAction<ParcelItem>) => {
            site.parcel = action.payload!;
        }
    }
});

export const {setParcel} = ParcelSlice.actions;

export const selectParid = (state:RootState) => state.parcel.parcel;

export default ParcelSlice.reducer;