import { Autocomplete, TextField, AutocompleteInputChangeReason, AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material'
import { useState } from 'react'
import { Box } from '@mui/system'
import { Grid } from '@mui/material'
import React from 'react';
import {

    selectName,
    selectTaxYear
} from '../county/countySlice'
import { useAppSelector } from '../../app/hooks';
import { fetchBook, fetchPage } from './salesBookPageAPI';


interface SalesbookPageProps {
    onBookPageChange: (value: Array<string>) => void
}

export const SalesBookPage = (props: SalesbookPageProps) => {


    const countyName = useAppSelector(selectName);
    const taxYear = useAppSelector(selectTaxYear);

    const [bookPage, setBookPage] = useState(['', ''])
    const [salesBook, setSalesBook] = useState('')
    const [salesPage, setSalesPage] = useState('')

    const [bookResults, setBookResults] = useState(['']);
    const [pageResults, setPageResults] = useState(['']);

    const doBookQuery = (value: string) => {
        if (value) {
            setSalesBook(value);

            fetchBook(countyName, taxYear, value).then(results => {
                setBookResults(results);
            })

            props.onBookPageChange([value, salesPage])
        }
    }

    const getBookResults = (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
        if (reason === 'clear' || reason === 'reset') {
            setBookResults([]);
        }
        if (value) {
            doBookQuery(value)
        }
    }

    const handleBookChange = (event: React.SyntheticEvent,
        value: any,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails) => {

        if (reason === 'clear') {
            setSalesBook('')
            setSalesPage('')
            setBookPage(['', ''])
            props.onBookPageChange(['', ''])
            return;
        }

        if (value === null) {
            value = '';
        }
        setSalesBook(value);

        setBookPage([value, salesPage])

        if (value > 2) {
            doPageQuery(value);
        }
        props.onBookPageChange([`${value}`, `${salesPage}`])
    }

    // -------------------- PAGE --------------------

    const doPageQuery = (value: string) => {
        setSalesPage(value);
        fetchPage(countyName, taxYear, salesBook, value).then(results => {
            setPageResults(results);
        })
    }

    const getPageResults = (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
        if (reason === 'clear' || reason === 'reset') {
            setPageResults([]);
        }
        if (value) {
            doPageQuery(value)
            props.onBookPageChange([`${salesBook}`, `${value}`])
        }
    }

    const handlePageChange = (event: React.SyntheticEvent,
        value: any,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails) => {

        if (value === null) {
            value = '';
        }

        if (reason === 'clear') {
            setSalesPage('')
            setBookPage([salesBook, ''])
            props.onBookPageChange([`${salesBook}`, ''])
            return;
        }

        setBookPage([salesBook, value])

        props.onBookPageChange([`${salesBook}`, `${value}`])
    }

    return (
        <Grid container columns={{ xs: 1, sm: 4, md: 8 }} rowSpacing={2} columnSpacing={{ xs: 4, sm: 8, md: 12 }} className="form-group">
            <Grid item>
                <Autocomplete
                    id='ias_autocomplete_book'
                    getOptionLabel={(bookResults) => `${bookResults}`}
                    options={bookResults}
                    sx={{ width: 300 }}
                    isOptionEqualToValue={(option, value) => option === value}
                    noOptionsText={"No results found"}
                    renderOption={(props, bookResults) => (
                        <Box component={'li'} {...props}>
                            {bookResults}
                        </Box>
                    )}
                    renderInput={(params) => <TextField {...params} label="Book" />}
                    onInputChange={getBookResults}
                    onChange={handleBookChange} />
            </Grid>

            <Grid item>
                <Autocomplete
                    id='ias_autocomplete_page'
                    getOptionLabel={(pageResults) => pageResults}
                    options={pageResults}
                    sx={{ width: 300 }}
                    isOptionEqualToValue={(option, value) => option === value}
                    noOptionsText={"No results found"}
                    renderOption={(props, pageResults) => (
                        <Box component={'li'} {...props}>
                            {pageResults}
                        </Box>
                    )}
                    renderInput={(params) => <TextField {...params} label="Page" />}
                    onChange={handlePageChange}
                    onInputChange={getPageResults}
                />
            </Grid>
        </Grid>
    )
}