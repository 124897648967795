import '../App.css';
import { PRC } from '../features/prc/PRC';


export function PRCView() {

    return (
        <PRC/>
    )

}