import { API_KEY, API_BASE_URL } from "../../app/constants";
import { ApiResult } from './districtsSlice';

export function fetchDistrict(jur: string, taxyr: any) {
    let url = `${API_BASE_URL}/query/${jur}/${taxyr}/parcel?apiKey=aw0YHOt4EUTZb0gQkP0=&returnFields=distinct taxdistrict as code,districtname as display&`;

    let Data = {
        status: "OK",
        data: [],
        length: 0,
        totalRecords: 0
    }

    return new Promise<ApiResult>((resolve,reject)=>{
        fetch(url, {headers: {'xc-auth':API_KEY}}).then((response)=>{
            response.json().then(Jdata=>{
                Data.status = Jdata!.status!;
                Data.length = Jdata!.length!;
                Data.totalRecords = Jdata!.totalRecords!;
                Data.data = Jdata!.data!;

                resolve(Data);

            }).catch((err)=>{
                console.log(err);
                Data.status = "ERROR";
                reject(Data);
            })
        }).catch((err)=>{
            console.log(err);
            Data.status = "ERROR";
            reject(Data);
        });
    })
}