import '../../App.css';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Paper, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { AttributeItem } from '../../components/attributeItem';
import { currencyFormatter, dateFormatter } from '../../app/hooks';
import { Box } from '@mui/system';
import csvDownload from 'json-to-csv-export'
import { SalesItem } from './salesSlice';
import { fetchSales } from './salesAPI';

type salesProps = {
    where: string,
    limit?: number,
    orderBy?: string
}


export function Sales({ where = '', limit = 10, orderBy = '' }: salesProps) {
    const { countyName, taxYear } = useParams()

    const [salesList, setSales] = useState(Array<SalesItem>);
    const [parcelWhere, setWhere] = useState('');
    const [nextPageId, setNextPageId] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0)


    function doQuery(where: string, orderBy: string) {
        if (countyName !== undefined && where !== undefined && where.length > 0) {
            fetchSales(countyName!, where!, limit, orderBy, 0).then(results => {
                setTotalRecords(results.totalRecords)
                setSales(results!.data);
                setNextPageId(results!.next_page_id!);
                setHasMore(results.totalRecords > results.length)
            });
        }
    }

    function doQueryLM(where: string, orderBy: string) {
        if (countyName !== undefined && where !== undefined && where.length > 0) {
            fetchSales(countyName!, where!, limit, orderBy, nextPageId).then(results => {
                setSales([...salesList, ...results!.data]);
                setNextPageId(results!.next_page_id!);
                setHasMore(salesList.length < totalRecords)
            });
        }
    }

    function handleLoadMore() {
        doQueryLM(where, orderBy)
    }

    useEffect(() => {
        setTotalRecords(0)
        if (where === null || where === undefined || where.length < 5) {
            var empty = new Array<SalesItem>();
            setSales(empty)
        } else {
            if (where !== parcelWhere) {
                var empty = new Array<SalesItem>();
                setSales(empty)
                doQuery(where, orderBy)
            } else {
                doQuery(where, orderBy)
            }

        }
        setWhere(where)
    }, [where, orderBy]);

    return (
        <Box>
            <Typography color={'text.secondary'} width={'100%'} align="center">
                {salesList?.length} Results {totalRecords > 0 && ` of ${totalRecords}`}
            </Typography>
            {salesList.map(pi => {
                return (
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} key={`summary-${pi.salekey}`} id={`panel-${pi.salekey}-header`} aria-controls={`panel-${pi.salekey}-content`}>
                            <Grid container columns={{ xs: 1, sm: 4, md: 8 }} rowSpacing={2} columnSpacing={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={1} sm={2} md={4}>
                                    <Typography sx={{ width: '50%' }}>
                                        {pi.parid}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <Typography sx={{ color: 'text.secondary', }}>
                                        {pi.own1}  |  {dateFormatter(pi.saledt)}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </AccordionSummary>
                        <AccordionDetails key={`detail-${pi.salekey}`}>
                            <Grid container columns={{ xs: 1, sm: 4, md: 8 }} rowSpacing={2} columnSpacing={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Parid" value={pi.parid} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Owner" value={pi.own1} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Owner2" value={pi.own2} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Seller" value={pi.oldown} />
                                </Grid>

                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Book/Page" value={`${pi.book}/${pi.page}`} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Sale Type" value={pi.saletype} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Sale Price" value={ currencyFormatter(pi.price)} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Sale Date" value={ dateFormatter(pi.saledt)} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <Button variant='contained' href={`/detail/${countyName}/${taxYear}/${pi.parid}`}>Details</Button>
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <Button variant='contained' href={`/prc/${countyName}/${taxYear}/${pi.parid}`}>PRC</Button>
                                </Grid>

                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                )
            })}
              <Grid container columns={{ xs: 1, sm: 4, md: 8 }} rowSpacing={2} columnSpacing={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item sx={{minWidth:'40%'}}>
                    {salesList.length > 0 && 
                        <Button variant='contained' onClick={()=>{
                            csvDownload({data:salesList, filename:`${countyName}_ParcelData.csv`,delimiter:','})
                        }}>Download Data</Button>
                    }
                </Grid>
                <Grid item sx={{minWidth:'40%', float: 'right'}}>
                    {hasMore && <Button variant='contained' onClick={handleLoadMore} >Load More</Button>}
                </Grid>
            </Grid>
            
        </Box>
    )
}