import { Box, Button, Divider, FormGroup, Grid, Slider, TextField, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import '../App.css';
import { useAppDispatch, useAppSelector } from '../app/hooks';

import {
    selectName, setCountyName,
    setTaxYear
} from '../features/county/countySlice';
import { Parcels } from '../features/parcels/Parcels';
import { BaseSyntheticEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useCustomEventListener } from '../app/events';
import { NbhdSearch } from '../features/Nbhd/Nbhd';
import { LandUseSelect } from '../features/landusecodes/landusecodes';
import { DistrictSelect } from '../features/districts/districts';


function YearToNumber(value: string | undefined) {
    if (value === undefined) {
        return 0;
    } else {
        return +value
    }
}

export function ParcelSearch() {

    const listenerOwn1 = useCustomEventListener('autocomplete-selected', (data) => {

    });

    const { countyName, taxYear } = useParams()

    const taxyear = YearToNumber(taxYear);

    const dispatch = useAppDispatch()

    const [parcelWhere, setParcelWhere] = useState('');
    const [parcelOrderBy, setParcelOrderBy] = useState('');
    const [nbhdValue, setNbhdValue] = useState('');
    const [lucValue, setLucValue] = useState('');
    const [districtValue, setDistrictValue] = useState('');
    const [minAcres, setMinAcres] = useState(0)
    const [maxAcres, setMaxAcres] = useState(10000)
    const [acresSet, setAcresSet] = useState(false)
    const [apprValue, setApprValue] = useState(0)
    const [landValue, setLandValue] = useState(0)
    const [bldgValue, setBldgValue] = useState(0)
    const [reset, setReset] = useState(false)

    const countyName1 = useAppSelector(selectName);
    if (countyName !== countyName1 && countyName !== undefined && taxYear !== undefined) {
        dispatch(setCountyName(countyName))
        dispatch(setTaxYear(+taxYear))
    }

    function handleMinAcresChange(e: BaseSyntheticEvent) {
        if (e != null) {
            setAcresSet(true);
            setMinAcres(+e.target.value)
        }
    }
    function handleMaxAcresChange(e: BaseSyntheticEvent) {
        if (e != null) {
            setAcresSet(true);
            setMaxAcres(+e.target.value)
        }
    }


    function calculateValue(value: number) {
        return 2 ** value;
    }

    function clearForm() {
        setReset(true);
        setLandValue(0)
        setBldgValue(0)
        setApprValue(0)
        setNbhdValue('')
        setLucValue('')
        setDistrictValue('')
        setParcelWhere('');
        setMinAcres(0);
        setMaxAcres(10000)
        setReset(false);
    }


    function valueLabelFormat(value: number) {
        const units = ['K', 'M', 'B', 'T'];

        let unitIndex = 0;
        let scaledValue = value;

        while (scaledValue >= 1000 && unitIndex < units.length - 1) {
            unitIndex += 1;
            scaledValue /= 1000;
        }

        return `$${scaledValue.toFixed(0)} ${units[unitIndex]}`;
    }

    function handleApprChange(event: Event | SyntheticEvent, value: number | number[]) {
        if (typeof value === 'number') {
            setApprValue(calculateValue(value));
        }
        //console
    }

    function handleLandChange(event: Event | SyntheticEvent, value: number | number[]) {
        if (typeof value === 'number') {
            setLandValue(calculateValue(value));
        }
        //console
    }
    function handleBldgChange(event: Event | SyntheticEvent, value: number | number[]) {
        if (typeof value === 'number') {
            setBldgValue(calculateValue(value));
        }
        //console
    }
    useEffect(() => {
        if (reset) {
            return;
        }
        let wheres = [];
        let orders = [];
        if (nbhdValue != null && nbhdValue.length > 1) {
            wheres.push(`nbhd eq '${nbhdValue}'`)
        }
        if (lucValue != null && lucValue.length > 1) {
            wheres.push(`luc eq '${lucValue}'`)
        }
        if (districtValue != null && districtValue.length > 1) {
            wheres.push(`taxdistrict eq '${districtValue}'`);
        }
        if (acresSet) {
            wheres.push(`acres gte ${minAcres}`)
            wheres.push(`acres lte ${maxAcres}`)
        }

        if (apprValue > 0) {
            wheres.push(`aprtot gte ${apprValue * 1000}`)
            orders.push('aprtot ASC')
        }
        if (landValue > 0) {
            wheres.push(`aprland gte ${landValue * 1000}`)
            orders.push('aprland ASC')
        }
        if (bldgValue > 0) {
            wheres.push(`aprbldg gte ${bldgValue * 1000}`)
            orders.push('aprbldg ASC')
        }


        let where = wheres.join(' and ');
        let order = orders.join(',');

        setParcelWhere(where);
        setParcelOrderBy(order);

    }, [nbhdValue, lucValue, districtValue, minAcres, maxAcres, apprValue, landValue, bldgValue])

    return (
        <div>
            <FormGroup ref={listenerOwn1}>
                <Grid container columns={{ xs: 1, sm: 4, md: 8 }} rowSpacing={2} columnSpacing={{ xs: 4, sm: 8, md: 12 }}>

                    <Grid item >
                        <NbhdSearch jur={`${countyName}`} taxyr={taxyear} nbhdChanged={(value: string) => { setNbhdValue(value) }} />
                    </Grid>
                    <Grid item >
                        <LandUseSelect onSelection={(value: string) => { setLucValue(value) }} />
                    </Grid>
                    <Grid item >
                        <DistrictSelect jur={`${countyName}`} taxyr={taxYear} onSelection={(value: string) => { setDistrictValue(value) }} />
                    </Grid>
                    <Grid item>
                        <TextField sx={{ maxWidth: 110 }} value={minAcres} defaultValue={minAcres} onChange={handleMinAcresChange} label="Min Acres"></TextField>
                        <TextField sx={{ maxWidth: 110 }} value={maxAcres} defaultValue={maxAcres} label="Max Acres" onChange={handleMaxAcresChange}></TextField>
                    </Grid>
                    <Grid item>
                        <Box>
                            <Typography id="appr-value-slider" gutterBottom>Appraised Value</Typography>
                            <Slider
                                aria-labelledby='appr-value-slider'
                                sx={{ minWidth: '300px' }}
                                min={0}
                                max={20}
                                defaultValue={0}
                                aria-label='Appraised Value'
                                getAriaValueText={valueLabelFormat}
                                valueLabelFormat={valueLabelFormat}
                                scale={calculateValue}
                                valueLabelDisplay="auto"
                                onChangeCommitted={handleApprChange}
                            ></Slider>
                        </Box>

                    </Grid>
                    <Grid item>
                        <Box>
                            <Typography id="land-value-slider" gutterBottom>Land Value</Typography>
                            <Slider
                                aria-labelledby='land-value-slider'
                                sx={{ minWidth: '300px' }}
                                min={0}
                                max={20}
                                defaultValue={0}
                                aria-label='Land Value'
                                getAriaValueText={valueLabelFormat}
                                valueLabelFormat={valueLabelFormat}
                                scale={calculateValue}
                                valueLabelDisplay="auto"
                                onChangeCommitted={handleLandChange}
                            ></Slider>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box>
                            <Typography id="bldg-value-slider" gutterBottom>Building Value</Typography>
                            <Slider
                                aria-labelledby='bldg-value-slider'
                                sx={{ minWidth: '300px' }}
                                min={0}
                                max={15}
                                defaultValue={0}
                                aria-label='Land Value'
                                getAriaValueText={valueLabelFormat}
                                valueLabelFormat={valueLabelFormat}
                                scale={calculateValue}
                                valueLabelDisplay="auto"
                                onChangeCommitted={handleBldgChange}
                            ></Slider>
                        </Box>
                    </Grid>
                </Grid>
            </FormGroup>
            <Button onClick={() => {
                clearForm();
            }}>Clear</Button>
            <Divider />
            <Typography color={'text.secondary'} width={'100%'}>Results</Typography>
            <Parcels where={parcelWhere} variant="parcel" limit={50} orderBy={parcelOrderBy} />
        </div>
    )
}