import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';


export interface LUCItem {
    code: string,
    display: string,
    id: number
}

export interface ApiResult {
    status: string,
    data: Array<LUCItem>,
    length: number,
    totalRecords: number
}

export interface LUCState {
    landuses: Array<LUCItem>
}

export const initialState: LUCState = {
    landuses: [] as Array<LUCItem>
}


export const LUCSlice = createSlice({
    name: "landusecodes",
    initialState,
    reducers: {
        setLanduses: (state, action: PayloadAction<Array<LUCItem>>) => {
            state.landuses = action.payload;
        }
    }
});

export const {setLanduses} = LUCSlice.actions;

export const selectLanduses =  (state:RootState) => state.landusecodes.landuses;

export default LUCSlice.reducer;
