import {
    Grid,
    TextField,
    Button,
    Box} from '@mui/material';
import MainLayout from './mainLayoutView';


export const LoginView = () => {



    return (
        <MainLayout>
            <div style={{ padding: 30 }}>
                <Box marginTop={'10%'} justifyItems={'center'} alignItems='center' justifyContent={'center'}>
                    <Grid container spacing={3} direction='column' justifyItems={'center'} alignItems='center'>
                        <Grid item xs={12}>
                            <TextField label="Username"></TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Password" type={'password'}></TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant='contained' fullWidth> Login </Button>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </MainLayout>


    )
}