import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { fetchSales } from './salesAPI';



export interface SalesItem {
    adjamt: number
    adjprice: number
    adjreas: number
    aprtot: number
    asmt: number
    book: number
    instrtyp: string
    instruno: number
    jur: string
    mktvalid: number
    newseq: number
    newyr: number
    nopar: number
    oldown: string
    oldown2: number
    oldseq: number
    oldyr: number
    own1: string
    own2: number
    page: number
    parid: string
    price: number
    saledt: string
    salekey: number
    saletype: string
    saleval: number
    seq: number
    source: number
    stampval: number
    steb: number
    stflag: number
  }
  


export interface ApiResult {
    status: string,
    data: Array<SalesItem>,
    length: number,
    totalRecords: number,
    next_page_id?: number
}

export interface SalesState {
    sales: Array<SalesItem>
    where: string,
    orderBy: string
}

export const initialState: SalesState = {
    sales: [],
    where: '',
    orderBy: ''
}

export const SalesSlice = createSlice({
    name: 'sales',
    initialState,
    reducers: {
        setSales: (site, action: PayloadAction<ApiResult>) => {
            site.sales = action.payload!.data!;
        },
        setWhere: (site, action: PayloadAction<string>) => {
            site.where = action.payload!;
        },
        setOrderBy: (site, action: PayloadAction<string>) => {
            site.orderBy = action.payload!;
        }
    }
});



export const { setSales, setWhere, setOrderBy } = SalesSlice.actions;

export const selectSales= (state: RootState) => state.sales.sales;
export const selectParid = (state: RootState) => state.sales.where;
export const selectOrderBy = (state: RootState) => state.sales.orderBy;



export const getSalesAsync =
    (jur: string, taxyr: number, where: string): AppThunk =>
        (dispatch, getState) => {
            let taxyr = getState().county.taxYear;
            let jur = getState().county.countyName;
            let where = getState().parcels.where;

            fetchSales(jur, where, 10).then(data => {
                dispatch(setSales(data));
            })
        };


export default SalesSlice.reducer;