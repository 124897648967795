import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material"
import { currencyFormatter, dateFormatter } from "../app/hooks"
import { AttributeItem } from "./attributeItem"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { SaleItem } from "../features/prc/prcSlice"

export interface SalesListOptions {
    sales: Array<SaleItem> | undefined
}


export const SalesList = (props: SalesListOptions) => {

    return (
        <div>
            {props.sales?.map(sale => {
                return (
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} key={`d-summary-${sale.salekey}`} >
                            <Typography width='15%'>{dateFormatter(sale.saledt)} </Typography> <Typography flexDirection='row' color={'text.secondary'}> {sale.saletype}</Typography>
                        </AccordionSummary>
                        <AccordionDetails key={`d-details-${sale.salekey}`}>
                            <Grid container columns={{ xs: 1, sm: 4, md: 8 }} rowSpacing={2} columnSpacing={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Buyer" value={sale.own1} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Seller" value={sale.oldown} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Sale Price" value={currencyFormatter(sale.price)} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Appraised" value={currencyFormatter(sale.aprtot)} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Book/Page" value={`${sale.book}/${sale.page}`} />
                                </Grid>
                            </Grid>
                        </AccordionDetails>

                    </Accordion>
                )
            })}
        </div>
    )
}