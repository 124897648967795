import { InputLabel, NativeSelect } from '@mui/material'
import { useState, useEffect } from 'react'
import { Box } from '@mui/system'
import React from 'react';
import { LUCItem } from './landusecodesSlice';
import { fetchLUC } from './landusecodesAPI';


export interface LandUseSelectProps {
    onSelection: (value: string) => void
}


export const LandUseSelect = (props: LandUseSelectProps) => {

    const [codes, setCodes] = useState([] as Array<LUCItem>)
    const [selcode, setSelCode] = useState('')



    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelCode(event.target.value as string);
        props.onSelection(event.target.value as string);
    };

    useEffect(() => {
        fetchLUC().then(data => {
            let tmp = [] as Array<LUCItem>;
            setCodes(data.data);
        });
    }, []);

    return (
        <Box>
            <InputLabel htmlFor="land_use_select">Land Use</InputLabel>
            <NativeSelect
                value={selcode}
                defaultValue={""}
                inputProps={{
                    name: "Land Use",
                    id: "land_use_select"
                }}
                onChange={handleChange}
            >

                <option value={""}>None</option>
                {codes!.map(cd => {
                    return (
                        <option value={cd.display}>{cd.display}</option>
                    )
                })}
            </NativeSelect>
        </Box>
    )

}