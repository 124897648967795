import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { fetchCounties } from "./countiesAPI"
import { CountyType } from "./countiesSlice"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { EditCounty } from "./EditCounty"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

export const Counties = () => {

    const [apicounties, setApiCounties] = useState<Array<CountyType>>([])
    const [hasEdits, setHasEdits] = useState(false)

    useEffect(() => {
        fetchCounties().then(data => {
            if (data.data && data.data.length > 0) {
                let tmpList = data.data.sort((a,b)=> +a.jur - +b.jur)
                setApiCounties(tmpList);
            }
        })

    }, [hasEdits])

    return (
        <Box>
            {apicounties.map(cnty => {
                return (
                    <Accordion key={cnty.jur}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} key={`summary-${cnty.jur}`} >
                            <Grid container columns={{ xs: 1, sm: 4, md: 8 }} rowSpacing={2} columnSpacing={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item>
                                    {cnty.isused && <CheckCircleOutlineIcon />}
                                    {cnty.isused == false && <CancelIcon />}
                                </Grid>
                                <Grid item>
                                    <Typography>{cnty.jur}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography color={'text.primary'}>{cnty.name}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography color={'text.secondary'}>{cnty.alias}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EditCounty cnty={cnty} />
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </Box>
    )
}