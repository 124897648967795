import { Box, Button, Divider, FormGroup, Grid, Slider, TextField, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import '../App.css';
import { useAppDispatch, useAppSelector } from '../app/hooks';

import {
    selectName, setCountyName,
    setTaxYear
} from '../features/county/countySlice';
import { BaseSyntheticEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useCustomEventListener } from '../app/events';
import { NbhdSearch } from '../features/Nbhd/Nbhd';
import { DistrictSelect } from '../features/districts/districts';
import { BldgStyleSelect } from '../features/bldgstyles/BldgStyles';
import { Dwellings } from '../features/dwellings/Dwellings';


function YearToNumber(value: string | undefined) {
    if (value === undefined) {
        return 0;
    } else {
        return +value
    }
}

export function DwellingSearch() {

    const listenerOwn1 = useCustomEventListener('autocomplete-selected', (data) => {

    });

    const { countyName, taxYear } = useParams()

    const taxyear = YearToNumber(taxYear);

    const dispatch = useAppDispatch()

    const [dwellingWhere, setDwellingWhere] = useState('');
    const [dwellingOrderBy, setDwellingOrderBy] = useState('');
    const [nbhdValue, setNbhdValue] = useState('');
    const [bldgStyle, setBldgStyle] = useState('');
    const [sqftla, setSqftla] = useState(0);
    const [bathrooms, setBathrooms] = useState(0);
    const [bedrooms, setBedrooms] = useState(0);
    const [districtValue, setDistrictValue] = useState('');
    const [minAcres, setMinAcres] = useState(0)
    const [maxAcres, setMaxAcres] = useState(10000)
    const [acresSet, setAcresSet] = useState(false)
    const [apprValue, setApprValue] = useState(0)
    //const [landValue, setLandValue] = useState(0)
    const [bldgValue, setBldgValue] = useState(0)
    const [reset, setReset] = useState(false)

    const countyName1 = useAppSelector(selectName);
    if (countyName !== countyName1 && countyName !== undefined && taxYear !== undefined) {
        dispatch(setCountyName(countyName))
        dispatch(setTaxYear(+taxYear))
    }

    function handleMinAcresChange(e: BaseSyntheticEvent) {
        if (e != null) {
            setAcresSet(true);
            setMinAcres(+e.target.value)
        }
    }
    function handleMaxAcresChange(e: BaseSyntheticEvent) {
        if (e != null) {
            setAcresSet(true);
            setMaxAcres(+e.target.value)
        }
    }


    function calculateValue(value: number) {
        return 2 ** value;
    }

    function clearForm() {
        setReset(true);
        setBathrooms(0)
        setBedrooms(0)
        setBldgValue(0)
        setApprValue(0)
        setNbhdValue('')
        setBldgStyle('')
        setDistrictValue('')
        setDwellingWhere('');
        setMinAcres(0);
        setMaxAcres(10000)
        setReset(false);
    }


    function valueLabelFormat(value: number) {
        const units = ['K', 'M', 'B', 'T'];

        let unitIndex = 0;
        let scaledValue = value;

        while (scaledValue >= 1000 && unitIndex < units.length - 1) {
            unitIndex += 1;
            scaledValue /= 1000;
        }

        return `${scaledValue.toFixed(0)} ${units[unitIndex]}`;
    }

    function handleSqftlaChange(event: Event | SyntheticEvent, value: number | number[]) {
        if (typeof value == 'number') {
            setSqftla(calculateValue(value));
        }
        //console
    }

    function handleBathroomChange(event: Event | SyntheticEvent, value: number | number[]) {
        if (typeof value == 'number') {
            setBathrooms(value);
        }
    }
    function handleBedroomChange(event: Event | SyntheticEvent, value: number | number[]) {
        if (typeof value == 'number') {
            setBedrooms(value);
        }
    }
    function handleBldgChange(event: Event | SyntheticEvent, value: number | number[]) {
        if (typeof value == 'number') {
            setBldgValue(calculateValue(value));
        }
        //console
    }
    useEffect(() => {
        if (reset) {
            return;
        }
        let wheres = [];
        let orders = [];
        if (nbhdValue != null && nbhdValue.length > 1) {
            wheres.push(`nbhd eq '${nbhdValue}'`)
        }
        if (bldgStyle != null && bldgStyle.length > 1) {
            wheres.push(`bldgstyle eq '${bldgStyle}'`)
        }
        if (districtValue != null && districtValue.length > 1) {
            wheres.push(`taxdistrict eq '${districtValue}'`);
        }
        if (sqftla > 0) {
            wheres.push(`sfla gte ${sqftla}`)
            orders.push('sfla ASC')
        }
        if (acresSet) {
            wheres.push(`acres gte ${minAcres}`)
            wheres.push(`acres lte ${maxAcres}`)
        }

        if (apprValue > 0) {
            wheres.push(`aprtot gte ${apprValue * 1000}`)
            orders.push('aprtot ASC')
        }
        if (bathrooms > 0) {
            wheres.push(`fixbath gte ${bathrooms}`)
            orders.push('fixbath ASC')
        }
        if (bedrooms > 0) {
            wheres.push(`rmbed gte ${bedrooms}`)
            orders.push('rmbed ASC')
        }
        if (bldgValue > 0) {
            wheres.push(`aprbldg gte ${bldgValue * 1000}`)
            orders.push('aprbldg ASC')
        }


        let where = wheres.join(' and ');
        let order = orders.join(',');

        setDwellingWhere(where);
        setDwellingOrderBy(order);

    }, [nbhdValue, bldgStyle, districtValue, bathrooms, bedrooms, sqftla, apprValue, bldgValue])

    return (
        <div>
            <FormGroup ref={listenerOwn1}>
                <Grid container columns={{ xs: 1, sm: 4, md: 8 }} rowSpacing={2} columnSpacing={{ xs: 4, sm: 8, md: 12 }}>

                    <Grid item >
                        <NbhdSearch jur={`${countyName}`} taxyr={taxyear} nbhdChanged={(value: string) => { setNbhdValue(value) }} />
                    </Grid>
                    <Grid item >
                        <BldgStyleSelect taxyr={taxyear} jur={countyName1} onSelection={(value: string) => { setBldgStyle(value) }} />
                    </Grid>
                    <Grid item >
                        <DistrictSelect jur={`${countyName}`} taxyr={taxYear} onSelection={(value: string) => { setDistrictValue(value) }} />
                    </Grid>
                    <Grid item>
                        <TextField sx={{ maxWidth: 110 }} value={minAcres} defaultValue={minAcres} onChange={handleMinAcresChange} label="Min Acres"></TextField>
                        <TextField sx={{ maxWidth: 110 }} value={maxAcres} defaultValue={maxAcres} label="Max Acres" onChange={handleMaxAcresChange}></TextField>
                    </Grid>
                    <Grid item>
                        <Box>
                            <Typography id="square-ft-slider" gutterBottom>Square Feet</Typography>
                            <Slider
                                aria-labelledby='square-ft-slider'
                                sx={{ minWidth: '300px' }}
                                min={0}
                                max={10}
                                defaultValue={0}
                                aria-label='Square Feet'
                                getAriaValueText={valueLabelFormat}
                                valueLabelFormat={valueLabelFormat}
                                scale={calculateValue}
                                valueLabelDisplay="auto"
                                onChangeCommitted={handleSqftlaChange}
                            ></Slider>
                        </Box>

                    </Grid>
                    <Grid item>
                        <Box>
                            <Typography id="bathroom-slider" gutterBottom>Bathrooms</Typography>
                            <Slider
                                aria-labelledby='bathroom-slider'
                                sx={{ minWidth: '300px' }}
                                min={0}
                                max={20}
                                defaultValue={0}
                                aria-label='Bathrooms'
                                valueLabelDisplay="auto"
                                onChangeCommitted={handleBathroomChange}
                            ></Slider>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box>
                            <Typography id="bedroom-slider" gutterBottom>Bedrooms</Typography>
                            <Slider
                                aria-labelledby='bathroom-slider'
                                sx={{ minWidth: '300px' }}
                                min={0}
                                max={20}
                                defaultValue={0}
                                aria-label='Bedrooms'
                                valueLabelDisplay="auto"
                                onChangeCommitted={handleBedroomChange}
                            ></Slider>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box>
                            <Typography id="bldg-value-slider" gutterBottom>Building Value</Typography>
                            <Slider
                                aria-labelledby='bldg-value-slider'
                                sx={{ minWidth: '300px' }}
                                min={0}
                                max={15}
                                defaultValue={0}
                                aria-label='Building Value'
                                getAriaValueText={valueLabelFormat}
                                valueLabelFormat={valueLabelFormat}
                                scale={calculateValue}
                                valueLabelDisplay="auto"
                                onChangeCommitted={handleBldgChange}
                            ></Slider>
                        </Box>
                    </Grid>
                </Grid>
            </FormGroup>
            <Button onClick={() => {
                clearForm();
            }}>Clear</Button>
            <Divider />
            <Typography color={'text.secondary'} width={'100%'}>Results</Typography>
            <Dwellings where={dwellingWhere} limit={50} orderBy={dwellingOrderBy} />
        </div>
    )
}