import { API_BASE_URL, API_KEY } from "../../app/constants";
import { DwellingApiResult } from "./dwellingsSlice";

export const fetchDwellings =(jur: string, taxyr: number, where: string, limit: number, orderBy?: string, nextPage?: number) => {
    let url = `${API_BASE_URL}/query/${jur}/${taxyr}/dwellsearch?apiKey=${API_KEY}`;
    if (where.length > 3) {
        url += `&where=${where}`;
    }
    url += `&limit=${limit}`;

    if(orderBy){
        url += `&order_by=${orderBy}`
    }

    if(nextPage && nextPage > 1) {
        url += `&page_id=${nextPage}`
    }

    let Data = {
        status: "OK",
        data: [],
        length: 0,
        totalRecords: 0,
        next_page_id: 1
    }

    return new Promise<DwellingApiResult>((resolve,reject)=>{
        fetch(url, {headers: {'xc-auth':API_KEY}}).then((response)=>{
            response.json().then(Jdata=>{
                Data.status = Jdata!.status!;
                Data.length = Jdata!.length!;
                Data.totalRecords = Jdata!.totalRecords!;
                Data.data = Jdata!.data!;
                Data.next_page_id = Jdata!.next_page_id!;

                resolve(Data);

            }).catch((err)=>{
                console.log(err);
                Data.status = "ERROR";
                reject(Data);
            })
        }).catch((err)=>{
            console.log(err);
            Data.status = "ERROR";
            reject(Data);
        })
    })
}