import '../App.css';
import MainLayout from './mainLayoutView';
//import CountiesAutocomplete from '../features/county/mainLayout';
import { dmp2Parid, useAppSelector } from '../app/hooks';

import { Mapcards } from '../features/mapcards/MapCard';
import { useParams } from 'react-router-dom';

export function MapCardView() {
  const { countyName, parid } = useParams()


  const parid1 = dmp2Parid(`${parid}`);

  const countyName1 = countyName === undefined ? "" : countyName;
    return (
    <MainLayout>
    <header className="App-header">
      <Mapcards countyName={countyName} parid={parid}></Mapcards>
    </header>
    </MainLayout>
    )
  }