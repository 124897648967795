import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import countyReducer from '../features/county/countySlice';
import dmpReducer from '../features/dmp/dmpSlice';
import sitePhotoReducer from '../features/sitephoto/sitePhotoSlice';
import parcelsReducer from '../features/parcels/parcelsSlice';
import parcelReducer from '../features/parcel/parcelSlice';
import prcReducer from '../features/prc/prcSlice';
import landuceRecuer from '../features/landusecodes/landusecodesSlice';
import districtReducer from '../features/districts/districtsSlice';
import bldgstylesSlice from '../features/bldgstyles/bldgstylesSlice';
import dwellingsSlice from '../features/dwellings/dwellingsSlice';
import salesSlice from '../features/sales/salesSlice';

export const store = configureStore({
  reducer: {
    county: countyReducer,
    dmp: dmpReducer,
    sitephoto: sitePhotoReducer,
    parcels: parcelsReducer,
    parcel: parcelReducer,
    prc: prcReducer,
    landusecodes: landuceRecuer,
    districts: districtReducer,
    bldgstyles: bldgstylesSlice,
    dwellings: dwellingsSlice,
    sales: salesSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
