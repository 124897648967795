import './App.css';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import { Search } from './views/searchView';
import { Home } from './views/homeView';
import { SketchView } from './views/sketchView';
import { SitePhotoView } from './views/sitePhotoView';
import { OwnerSearch } from './views/ownerSearchView';
import { ParcelDetailView } from './views/parcelDetailView';
import { PRCView } from './views/prcView';
import { CountiesView } from './views/countiesView';
import { LoginView } from './views/loginView';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';
import { AboutView } from './views/aboutView';
import { MapCardView } from './views/mapcardView';

function App() {



  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/search/:countyName/:taxYear' element={<Search />} />
          <Route path='/sketch/:countyName/:taxYear/:parid' element={<SketchView />} />
          <Route path='/sitephotos/:countyName/:taxYear/:parid' element={<SitePhotoView />} />
          <Route path='/mapcards/:countyName/:parid' element={<MapCardView />} />
          <Route path='/detail/:countyName/:taxYear/:parid' element={<ParcelDetailView />} />
          <Route path='/prc/:countyName/:taxYear/:parid' element={<PRCView />} />
          <Route path='/admin/counties' element={<CountiesView />} />
          <Route path='/login' element={<LoginView />} />
          <Route path='/about' element={<AboutView/>} />
        </Routes>
      </BrowserRouter>
    </ReactKeycloakProvider>
  )
}


export default App;
