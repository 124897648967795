import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { fetchPhotos } from './sitePhotoAPI';


export interface SitePhotoItem {
    dmp: string,
    id: number,
    jur: string,
    name: string,
    parid: string,
    suffix: string,
    taxyr: number,
    url: string
}

export interface ApiResult {
    status: string,
    data: Array<SitePhotoItem>,
    length: number,
    totalRecords: number
}

export interface SitePhotoState {
    photos: Array<SitePhotoItem>
    parid: string
}

export const initialState: SitePhotoState = {
    photos:[],
    parid: ''
}

export const SitePhotoSlice = createSlice({
    name: 'sitephoto',
    initialState,
    reducers: {
        setPhotos: (site, action: PayloadAction<ApiResult>) => {
            site.photos = action.payload!.data!;
        },
        setParid: (site, action: PayloadAction<string>) => {
            site.parid = action.payload;
        }
    }
});

export const {setPhotos, setParid} = SitePhotoSlice.actions;

export const selectPhotos= (state:RootState) => state.sitephoto.photos;
export const selectParid = (state:RootState) => state.sitephoto.parid;

export const getPhotosAsync =
(jur: string, taxyr: number, parid: string): AppThunk =>
(dispatch, getState) => {
  let taxyr = getState().county.taxYear;
  let jur = getState().county.countyName;
  let parid = getState().dmp.taxdistrict;
  
  fetchPhotos(jur,taxyr,parid).then(data=>{
      dispatch(setPhotos(data));
  })
};


export default SitePhotoSlice.reducer;