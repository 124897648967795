import './mapbox-gl.css';
import '../../App.css';
import './svg/mapboxgl-ctrl-compass.svg';
import './svg/mapboxgl-ctrl-geolocate.svg';
import './svg/mapboxgl-ctrl-zoom-in.svg';
import './svg/mapboxgl-ctrl-zoom-out.svg';

import React, { useRef, useEffect, useState } from 'react';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { featureEach, bbox, centerOfMass, buffer } from '@turf/turf';
import { API_BASE_URL, API_KEY } from '../../app/constants';

mapboxgl.accessToken = 'pk.eyJ1Ijoicm1icmF0Y2hlciIsImEiOiJjaXQ0dWxnamcwMDBjMm9xMDV2cXhzZXVjIn0.o0VR32m_rtAqev1Qk-pkEg';


type SketchProps = {
    county: string | undefined,
    taxyr: number | string | undefined
    parid: string | undefined
    card: number | undefined
}


export const Sketch = (props: SketchProps) => {


    var map:mapboxgl.Map;

    const mapContainer = useRef(null);
    const [lng, setLng] = useState(-80.69);
    const [lat, setLat] = useState(38.72);
    const [zoom, setZoom] = useState(18);
    const [mapLoaded, setMapLoaded] = useState(false);


    function setUpData(sketchData: GeoJSON.FeatureCollection){
        if (map === undefined || map === null) return;
        

        map.addSource('sketch', {
            type: 'geojson',
            data: sketchData
        });


       var pf = sketchData.features.filter(feature => feature.geometry.type === 'Polygon');

        var polys: GeoJSON.FeatureCollection = {
            type: "FeatureCollection",
            features: pf
        }

        map.addSource('buildings',{
            type: 'geojson',
            data: polys
        })

        map.addLayer({
            id: 'sketch-building',
            type: 'fill-extrusion',
            source: 'buildings',
            paint: {
                'fill-extrusion-color': {
                    type: 'identity',
                        property: 'fill'
                    },
                'fill-extrusion-height': {
                    type: 'identity',
                    property: 'height'
                },
                'fill-extrusion-base': {
                    // Get fill-extrusion-base from the source 'base_height' property.
                    'property': 'base',
                    'type': 'identity'
                },
                'fill-extrusion-opacity': 0.9
            },
            filter: ['==', '$type', 'Polygon']
        });

        var centroids: GeoJSON.FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        featureEach(polys,function (currentFeature, featureIndex) {
            var f = centerOfMass(currentFeature);
            f.properties = currentFeature.properties;
            centroids.features.push(f);
        });

        map.addSource('building-labels',{
            type: 'geojson',
            data: centroids
        })

        map.addLayer({
            'id': 'sketch-building-label',
            'type': 'symbol',
            'source': 'building-labels',
            'layout': {
                'text-field': '{label}',
                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                'text-offset': [0, 0],
                'text-size': 25,
                'text-anchor': 'center'
            },
            'paint': {
                'text-color': '#FFFFFF',
                'text-halo-color': '#000000',
                'text-halo-width': 1,
                'text-halo-blur': 1
            }
        });

        map.addLayer({
            'id': 'label-layer',
            'type': 'symbol',
            'source': 'sketch',
            'layout': {
                'text-field': '{label}',
                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                'text-offset': [0, 0],
                'text-size': 15,
                'text-anchor': 'center'
            },
            'paint': {
                'text-color': '#FFFFFF',
                'text-halo-color': '#000000',
                'text-halo-width': 1,
                'text-halo-blur': 1
            },
            'filter': ['==', '$type', 'Point']
        });

        var nbounds = bbox(buffer(polys,50, {units: 'feet'}));
        var mbounds = new mapboxgl.LngLatBounds([nbounds[0],nbounds[1],nbounds[2],nbounds[3]]);
        map.fitBounds(mbounds, {padding: 30});

        map.on('click', 'sketch-building', (e:any) => {
            var tbl = '';
            if (e.features !== undefined) {
                var p = e.features[0].properties;
                if(p !== undefined && p !== null){
                    tbl +=`<table class="ias-map-popup"><tr><td>area</td><td>${p.area}</td></tr> `;
                    if (p.hasOwnProperty('lower')) {
                        tbl += `<tr><td>lower</td><td>${p.lower}</td></tr>`;
                    }
                    if (p.hasOwnProperty('first')) {
                        tbl += `<tr><td>first</td><td>${p.first}</td></tr>`;
                    }
                    if (p.hasOwnProperty('second')) {
                        tbl += `<tr><td>second</td><td>${p.second}</td></tr>`;
                    }
                    if (p.hasOwnProperty('third')) {
                        tbl += `<tr><td>third</td><td>${p.third}</td></tr>`;
                    }
                }
                tbl += '</table>';
            }
            let popup =  new mapboxgl.Popup({className: "ias-popup"})
                .setLngLat(e.lngLat)
                .setHTML(tbl);
            if(map !== null) {
                popup.addTo(map);
            }
        });
    }

    useEffect(()=>{

        const node = mapContainer.current;

        if(typeof window === undefined || node === null) return;


        if(map === null || map === undefined || mapLoaded === false)  {
        
            map = new mapboxgl.Map({
                container: node,
                style: 'mapbox://styles/rmbratcher/cj8lr7kcr640y2rpjjanw9oec',
                center: [lng,lat],
                zoom: zoom,
                preserveDrawingBuffer: true
            });

            map.addControl(new mapboxgl.NavigationControl());

            map.on('load',()=>{
                let url = `${API_BASE_URL}/sketch/${props.county}/${props.taxyr}/${props.parid}?apiKey=${API_KEY}`
                if(props.card){
                    url += `&card=${props.card}`
                }
                fetch(url).then(response=>{
                    response.json().then((sketchData)=>{
                        setUpData(sketchData);
                    });
                })
            });
            setMapLoaded(true);
        }
    },[]);

    return (
        <div style={{width: "100%", height: "100%", overflow: 'visible', position: 'relative', bottom: '0px'}} ref={mapContainer} className="map-container"></div>
    )

}