import { Divider, FormGroup, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import '../App.css';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import Typeahead from '../features/typeahead/Typeahead';

import {
    selectName, setCountyName, setTaxYear
} from '../features/county/countySlice';
import { API_BASE_URL } from '../app/constants';
import { Parcels } from '../features/parcels/Parcels';
import { useEffect, useState } from 'react';
import { useCustomEventListener } from '../app/events';
import { DeedBookPage } from '../features/DeedBookPage/DeedBookPage';
import { Own1Own2 } from '../features/Own1Own2/Own2Own2';


/*function YearToNumber(value: string | undefined) {
    if (value == undefined) {
        return 0;
    } else {
        return +value
    }
}*/

export function OwnerSearch() {

    const listenerOwn1 = useCustomEventListener('autocomplete-selected', (data) => {

    });

    const { countyName, taxYear } = useParams()


    const dispatch = useAppDispatch()

    const [parcelWhere, setParcelWhere] = useState('');
    const [dmpValue, setDmpValue] = useState('');
    const [parcelValue, setParcelValue] = useState('');
    const [bookPage, setBookPage] = useState(['', '']);
    const [own1own2, setOwn1Own2] = useState(['', '']);
    const [ownerAddr, setOwnerAddr] = useState('');


    const countyName1 = useAppSelector(selectName);
    if (countyName !== countyName1 && countyName !== undefined && taxYear !== undefined) {
        dispatch(setCountyName(countyName))
        dispatch(setTaxYear(+taxYear))
    }

    function dmpDisabled() {
        if (parcelValue) {
            return parcelValue.length > 1;
        } else {
            return false
        }
    }

    function paridDisabled() {
        if (dmpValue) {
            return dmpValue.length > 1;
        } else {
            return false
        }
    }

    useEffect(() => {
        let wheres = [];
        if (own1own2.length > 0) {
            if (own1own2[0] != null && own1own2[0]!.length > 0) {
                wheres.push(`own1 lk ${own1own2[0]}`)
            }
            if (own1own2.length > 1 && own1own2[1] != null && own1own2[1]!.length > 0) {
                wheres.push(`own2 lk ${own1own2[1]}`)
            }
        }
        if (bookPage.length > 0) {
            if (bookPage[0] != null && bookPage[0]!.length > 0) {
                wheres.push(`book lk ${bookPage[0]}`)
            }
            if (bookPage.length > 1 && bookPage[1] != null && bookPage[1]!.length > 0) {
                wheres.push(`page lk ${bookPage[1]}`)
            }
        }
        if (parcelValue != null && parcelValue!.length > 0) {
            wheres.push(`parid eq '${parcelValue}'`)
        }
        if (dmpValue != null && dmpValue.length > 0) {
            wheres.push(`dmp eq '${dmpValue}'`)
        }
        if(ownerAddr != null && ownerAddr.length > 0) {
            wheres.push(`owneraddr eq '${ownerAddr}'`)
        }

        let where = wheres.join(' and ');

        setParcelWhere(where);


    }, [own1own2, bookPage, parcelValue, dmpValue, ownerAddr])

    return (
        <div>
            <FormGroup ref={listenerOwn1}>
                <Grid container sx={{maxHeight: '70%'}} columns={{ xs: 1, sm: 4, md: 8 }} rowSpacing={2} columnSpacing={{ xs: 4, sm: 8, md: 12 }}>

                    <Grid item >
                        <Typeahead
                            url={`${API_BASE_URL}/typeahead/${countyName}/${taxYear}/parcel/parid`}
                            label='Parcel ID'
                            county={countyName}
                            taxyr={taxYear}
                            onValueChange={setParcelValue}
                            disabled={paridDisabled()}
                        />

                    </Grid>
                    <Grid item >
                        <Typeahead
                            url={`${API_BASE_URL}/typeahead/${countyName}/${taxYear}/parcel/dmp`}
                            label='District Map Parcel'
                            county={countyName}
                            taxyr={taxYear}
                            onValueChange={setDmpValue}
                            disabled={dmpDisabled()}
                        />
                    </Grid>


                    <Grid item >
                        <Own1Own2 onOwn1Own2Change={setOwn1Own2} />
                    </Grid>

                    <Grid item >
                        <Typeahead
                            url={`${API_BASE_URL}/typeahead/${countyName}/${taxYear}/parcel/owneraddr`}
                            label='Owner Address'
                            county={countyName}
                            taxyr={taxYear}
                            onValueChange={setOwnerAddr}
                        />
                    </Grid>

                    <Grid item>
                        <DeedBookPage onBookPageChange={setBookPage} />
                    </Grid>
                </Grid>
            </FormGroup>
            <Divider />
            <Parcels where={parcelWhere} limit={50} />
        </div>
    )
}