import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import '../App.css';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import MainLayout from './mainLayoutView';

import {
  selectName,
  setCountyName,
  setTaxYear
} from '../features/county/countySlice'
import { useState } from 'react';
import { OwnerSearch } from './ownerSearchView';
import { ParcelSearch } from './parcelSearchView';
import { DwellingSearch } from './dwellingSearchView';
import { SalesSearch } from './salesSearchView';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

/*
function YearToNumber(value: string | undefined) {
  if (value === undefined) {
    return 0;
  } else {
    return +value
  }
}*/

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function Search() {

  const { countyName, taxYear } = useParams()

  //const taxyear = YearToNumber(taxYear);

  const dispatch = useAppDispatch()


  const countyName1 = useAppSelector(selectName);
  if (countyName !== countyName1 && countyName !== undefined && taxYear !== undefined) {
    dispatch(setCountyName(countyName))
    dispatch(setTaxYear(+taxYear))
  }

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <MainLayout>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '99%' }}>
        <Tabs sx={{ width: '100%' }} value={value}
          onChange={handleChange}
          variant="scrollable"
          allowScrollButtonsMobile
          scrollButtons="auto" >
          <Tab label="Owner Search" {...a11yProps(0)} />
          <Tab label="Parcel Search" {...a11yProps(1)} />
          <Tab label="Dwelling Search" {...a11yProps(2)} />
          <Tab label="Sales Search" {...a11yProps(3)} />
          <Tab label="Help" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <OwnerSearch />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ParcelSearch />
      </TabPanel>
      <TabPanel value={value} index={2}>
          <DwellingSearch />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <SalesSearch />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Grid container columns={4}>
          <Grid item>
            <h5>Owner Search</h5>
            <p>
              Only fill one search box at a time, the search will happen automatically.  Filling multiple boxes will reduce the chances of a successufll search. Once results have been displayed then use the remaining boxes to narrow the results.
            </p>
            <h5>Parcel Search</h5>
            <p>
              Only fill one search box at a time, the search will happen automatically.  Filling multiple boxes will reduce the chances of a successufll search. Once results have been displayed then use the remaining boxes to narrow the results.
            </p>
          </Grid>
        </Grid>
      </TabPanel>
    </MainLayout>
  )
}