import '../App.css';
import MainLayout from './mainLayoutView';
import { useParams } from 'react-router-dom';
import { Backdrop, Badge, Button, CircularProgress, Divider, Grid, Tab, Tabs, Typography, Theme, useTheme } from '@mui/material';

import { useAppDispatch, dmp2Parid, mapLinkGenerator } from '../app/hooks';
import {
    setCountyName, setTaxYear
} from '../features/county/countySlice'

import {
    CardsData,
    setApiResult,
    DwellingItem,
    PhotoItem,
    SaleItem,
    MapOptItem
} from '../features/prc/prcSlice'

import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { DwellingsList } from '../components/dwellingsList';
import { OwnerDetail } from '../components/ownerdetail';
import { ParcelDetail } from '../components/parceldetail';
import { SalesList } from '../components/salesList';
//import { SitePhotoDetail } from '../components/sitephotosdetail';
import { fetchPRCData } from '../features/prc/prcAPI';
import { useKeycloak } from '@react-keycloak/web';
import { SitePhotosGallery } from '../components/sitephotosgallery';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


export function ParcelDetailView() {

    const theme = useTheme();

    const dispatch = useAppDispatch()

    const { keycloak } = useKeycloak()

    const { countyName, taxYear, parid } = useParams()


    const parid1 = dmp2Parid(`${parid}`);
    const taxYear1 = taxYear === undefined ? 0 : +taxYear;

    const countyName1 = countyName === undefined ? "" : countyName;

    if (countyName1 !== "") {
        dispatch(setCountyName(countyName1));
        dispatch(setTaxYear(taxYear1));
    }

    const [currentCard, setCurrentCard] = useState<CardsData>()
    const [dwellings, setDwellings] = useState<Array<DwellingItem>>();
    const [photos, setPhotos] = useState<Array<PhotoItem>>();
    const [sales, setSales] = useState<Array<SaleItem>>();

    const [value, setValue] = useState(0);
    const [isloading, setIsLoading] = useState(true);
    const [dwellingCount, setDwellingCount] = useState(0)
    const [salesCount, setSalesCount] = useState(0)
    const [photoCount, setPhotoCount] = useState(0)
    const [countyOpts, setMapOpts] = useState<MapOptItem>()
    const [noCard, setNoCard] = useState(false)

    const CanShowPhotos = () => {
        let auth = false;
        if (keycloak) {
            auth = keycloak.authenticated === true ? true : false;
        }
        let sp = countyOpts?.show_photos === true ? true : false;
        return auth || sp
    }

    const HasDwellings = () => {

        if (dwellings === undefined || dwellings.length < 1) {
            return false
        }
        return true
    }

    const HasSales = () => {
        if (sales === undefined || sales.length < 1) {
            return false
        }
        return true
    }


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    /*
    if (PrcCards.length === undefined || PrcCards.length < 1) {

    }
    */
    useEffect(() => {
        fetchPRCData(countyName1, taxYear1, parid1).then(tables => {
            dispatch(setApiResult(tables));

            setSalesCount(tables.sales.length);

            if (tables.data && tables.data.length > 0) {

                setCurrentCard(tables.data[0]);


                let ds = [] as Array<DwellingItem>;
                //let scount = 0;
                tables.data.map(card => {
                    card.dwellings?.map(d => {
                        ds.push(d);
                        return 0;
                    });
                    //scount += card.addns.length;
                    return 0;
                })

                setDwellings(ds);
                setDwellingCount(ds.length);
                setNoCard(false)
            } else {
                setNoCard(true)
            }




            setSales(tables.sales);
            setSalesCount(tables.sales.length);


            if (tables.mapOpts && tables.mapOpts.length > 0) {
                setMapOpts(tables.mapOpts[0]);

                let imgPhotos: Array<PhotoItem> = [];
                tables.photos.map(photo => {
                    if (photo.taxyr !== 999 && photo.taxyr !== 1200) {
                        imgPhotos.push(photo)
                    }
                    return 0;
                })
                setPhotos(imgPhotos)
                setPhotoCount(imgPhotos.length);

            }

            setIsLoading(false);
        });
    }, []);

    return (
        <MainLayout>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme: Theme) => theme.zIndex.drawer + 1 }}
                open={isloading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {currentCard &&
                <div>
                    <h2>Parcel Details</h2>
                    <h3>{currentCard.parcel.parid} ({currentCard.parcel.dmp})</h3>
                    <Grid container direction="row" sx={{ width: '100%' }}>
                        <Grid item>
                            {countyOpts?.map_link &&
                                <Button target="_blank" href={mapLinkGenerator(countyOpts.map_link, currentCard.parcel)}>Map Link</Button>
                            }
                        </Grid>
                        <Grid item>
                            <Button href={`/prc/${countyName1}/${taxYear1}/${currentCard.parcel.parid}`}>PRC</Button>
                        </Grid>
                    </Grid>
                    <Box sx={{ maxWidth: { xs: 360, sm: 480, md: 880, lg: 1000, xl: 1400 } }}>
                        <Tabs
                            sx={{ width: '100%' }}
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            allowScrollButtonsMobile
                            scrollButtons={true}
                            aria-label="Parcel Details"
                        >
                            <Tab label={<Typography sx={{ marginRight: '6px' }} >Owner</Typography>} {...a11yProps(0)} />
                            <Tab label={<Typography sx={{ marginRight: '6px' }} >Parcel</Typography>} />
                            <Tab label={<Badge color="success" showZero={true} badgeContent={dwellingCount}>
                                <Typography sx={{ marginRight: '6px' }} >Dwellings</Typography>
                            </Badge>
                            } {...a11yProps(2)} />

                            <Tab label={<Badge color="success" showZero={true} badgeContent={salesCount}>
                                <Typography sx={{ marginRight: '6px' }} >Sales</Typography></Badge>} {...a11yProps(3)} />

                            {CanShowPhotos() &&
                                <Tab label={<Badge color="success" showZero={true} badgeContent={photoCount}><Typography sx={{ marginRight: '6px' }} >Photos</Typography>⁢ </Badge>} {...a11yProps(4)} />
                            }
                        </Tabs>
                        <Divider />
                    </Box>
                    <Grid container columns={{ xs: 1, sm: 1, md: 1 }}>
                        <Grid item xs={2} sm={4} md={6}>
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                <OwnerDetail parcel={currentCard.parcel} />
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                <ParcelDetail parcel={currentCard.parcel} />
                            </TabPanel>
                            <TabPanel value={value} index={2} dir={theme.direction}>
                                {HasDwellings() &&
                                    <DwellingsList data={dwellings!} countyName={countyName1} taxyr={taxYear1} />
                                }
                                {HasDwellings() === false &&
                                    <div></div>
                                }
                            </TabPanel>


                            <TabPanel value={value} index={3} dir={theme.direction}>
                                {HasSales() &&
                                    <SalesList sales={sales} />
                                }
                                {HasSales() === false &&
                                    <div></div>
                                }
                            </TabPanel>


                            <TabPanel value={value} index={4} dir={theme.direction}>
                                {CanShowPhotos() &&
                                    //<SitePhotoDetail photos={photos!} />
                                    <SitePhotosGallery photos={photos!} />
                                }
                                {CanShowPhotos() === false &&
                                    <h3>Photos not for display</h3>
                                }
                            </TabPanel>

                        </Grid>
                    </Grid>
                </div>
            }
            {noCard &&
                <h3> No Data Available</h3>
            }
        </MainLayout>
    )

}