import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';


export interface BldgApiResult {
    status: string
    data: Styles[]
    length: number
    totalRecords: number
  }
  
  export interface Styles{
    bldgstyle?: string
  }

  export interface BldgStyles {
    styles: Array<string>
  }

  export const initialState: BldgStyles = {
    styles: [] as Array<string>
  }

  export const BldgStylesSlice = createSlice({
    name:'bldgstyles',
    initialState,
    reducers: {
        setStyles: (state, action: PayloadAction<Array<string>>) => {
                state.styles = action.payload;
        }
    }
  });

  export const {setStyles} = BldgStylesSlice.actions;

  export const selectBlgStyles = (state:RootState) => state.bldgstyles.styles;

  export default BldgStylesSlice.reducer;