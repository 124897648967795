import { Autocomplete, TextField, AutocompleteInputChangeReason, AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import React from 'react';
import {
    selectName,
    selectTaxYear
} from '../county/countySlice';
import { useAppSelector } from '../../app/hooks';
import { fetchOwn1Values, fetchOwn2Values } from './Own1Own2API';


interface own1Own2Props {
    onOwn1Own2Change: (value: Array<string>) => void
}

export const Own1Own2 = (props: own1Own2Props) => {


    const countyName = useAppSelector(selectName);
    const taxYear = useAppSelector(selectTaxYear);

    const [own1Own2, setOwn1Own2] = useState(['', ''])
    const [own1, setOwn1] = useState('')
    const [own2, setOwn2] = useState('')

    const [own1Results, setOwn1Results] = useState(['']);
    const [own2Results, setOwn2Results] = useState(['']);

    const doOwn1Query = (value: string) => {
        if (value) {
            fetchOwn1Values(countyName, taxYear, value).then(data => {
                setOwn1Results(data);
            })
        }
    }

    const getOwn1Results = (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
        if (reason === 'clear' || reason === 'reset') {
            setOwn1Results([]);
        }
        if (value) {
            doOwn1Query(value)
        }
    }

    const handleOwn1Change = (event: React.SyntheticEvent,
        value: any,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails) => {

        setOwn1(value);

        if (value != null && value.length > 2) {
            doOwn2Query(value);
        }

        props.onOwn1Own2Change([value, own2])
    }

    // -------------------- OWN2 --------------------

    const doOwn2Query = (value: string) => {
        if(value){
            fetchOwn2Values(countyName, taxYear, own1, value).then(data=>{
                setOwn2Results(data);
            })
        }
    }

    const getOwn2Results = (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
        if (reason === 'clear' || reason === 'reset') {
            setOwn2Results([]);
        }
        if (value) {
            doOwn2Query(value)
        }
    }

    const handleOwn2Change = (event: React.SyntheticEvent,
        value: any,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails) => {

        setOwn1Own2([own1, value])

        props.onOwn1Own2Change([own1, `${value}`])
    }

    return (
        <Grid container columns={{ xs: 1, sm: 4, md: 8 }} rowSpacing={2} columnSpacing={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item>
                <Autocomplete
                    id='ias_autocomplete_own1'
                    getOptionLabel={(own1Results) => own1Results}
                    options={own1Results}
                    sx={{ width: 300 }}
                    isOptionEqualToValue={(option, value) => option === value}
                    noOptionsText={"No results found"}
                    renderOption={(props, own1Results) => (
                        <Box component={'li'} {...props}>
                            {own1Results}
                        </Box>
                    )}
                    renderInput={(params) => <TextField {...params} label=" Owner Name 1" />}
                    onInputChange={getOwn1Results}
                    value={own1}
                    onChange={handleOwn1Change} />
            </Grid>
            <Grid item>
                <Autocomplete
                    id='ias_autocomplete_own2'
                    getOptionLabel={(own2Results) => own2Results}
                    options={own2Results}
                    sx={{ width: 300 }}
                    isOptionEqualToValue={(option, value) => option === value}
                    noOptionsText={"No results found"}
                    renderOption={(props, own2Results) => (
                        <Box component={'li'} {...props}>
                            {own2Results}
                        </Box>
                    )}
                    renderInput={(params) => <TextField {...params} label=" Owner Name 2" />}
                    onChange={handleOwn2Change}
                    value={own2}
                    onInputChange={getOwn2Results}
                />
            </Grid>
        </Grid>
    )
}