import '../App.css';
import MainLayout from './mainLayoutView';
//import CountiesAutocomplete from '../features/county/mainLayout';
import { dmp2Parid, useAppSelector } from '../app/hooks';

import { SitePhotos } from '../features/sitephoto/SitePhoto';
import { useParams } from 'react-router-dom';

export function SitePhotoView() {
  const { countyName, taxYear, parid } = useParams()


  const parid1 = dmp2Parid(`${parid}`);
  const taxYear1 = taxYear === undefined ? 0 : +taxYear;

  const countyName1 = countyName === undefined ? "" : countyName;
    return (
    <MainLayout>
    <header className="App-header">
      <SitePhotos countyName={countyName} taxYear={taxYear1} parid={parid}></SitePhotos>
    </header>
    </MainLayout>
    )
  }
  