import React from "react"
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Box, Button, Dialog, Grid, IconButton, Slide, Toolbar, Typography } from "@mui/material"
import { DwellingItem } from "../features/prc/prcSlice"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AttributeItem } from "./attributeItem"
import { Sketch } from "../features/sketch/Sketch"
import { useState } from "react"
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions"

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export function DwellingsList(props: { data: Array<DwellingItem>, countyName: string, taxyr: number }) {

    const [sketchOpen, setSketchOpen] = useState(false);
    const [curCard, setCurCard] = useState(1);

    const handleSketchOpen = (card: number) => {
        setCurCard(card);
        setSketchOpen(true);
    }
    const handleSketchClose = () => setSketchOpen(false);

    const handleClose = () => {
        setSketchOpen(false);
    }

    return (
        <div>
            {props.data?.map(dwelling => {
                return (
                    <Accordion defaultExpanded={dwelling.card == 1}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} key={`d-summary-${dwelling.card}`} >
                            Building {dwelling.card}
                        </AccordionSummary>
                        <AccordionDetails key={`d-details-${dwelling.card}`}>
                            <Grid container columns={{ xs: 2, sm: 4, md: 8 }} rowSpacing={2} columnSpacing={{ xs: 4, sm: 8, md: 12 }}>
                            <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Bedrooms" value={dwelling.rmbed} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Bathrooms" value={dwelling.fixbath} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="SqFt" value={dwelling.sfla} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Year Built" value={dwelling.yrblt} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Rooms" value={dwelling.rmtot} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Exterior" value={dwelling.exterior} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Heat" value={dwelling.heatsystem} />
                                    {dwelling.heattype}
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Style" value={dwelling.bldgstyle} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Stories" value={dwelling.stories} />
                                </Grid>
                                <Grid item>
                                <Button variant="contained" onClick={() => {
                                    handleSketchOpen(dwelling.card);
                                }}>Sketch</Button>
                                </Grid>
                                
                            </Grid>
                        </AccordionDetails>

                    </Accordion>
                )
            })}
            <Dialog
                fullScreen
                open={sketchOpen}
                onClose={handleSketchClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Sketch
                        </Typography>

                    </Toolbar>
                </AppBar>
                <Box height={'100%'}>
                    <Sketch parid={props.data[0].parid} county={props.countyName} taxyr={props.taxyr} card={curCard} />
                </Box>
            </Dialog>
        </div>
    )
}