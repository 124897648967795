import { Button, Checkbox, FormControl, FormControlLabel, TextField } from "@mui/material";
import { ChangeEvent, ChangeEventHandler, ReactEventHandler, useState } from "react";
import { updateCounty } from "./countiesAPI";
import { CountyType } from "./countiesSlice";
import { useKeycloak } from "@react-keycloak/web";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


export const EditCounty = (props: { cnty: CountyType }) => {


    const [showMapCard, setShowMapCard] = useState(props.cnty.show_mapcard)
    const [showPhotos, setShowPhotos] = useState(props.cnty.show_photos)
    const [mapLink, setMapLink] = useState(props.cnty.map_link || '')
    const [logoUrl, setLogoUrl] = useState(props.cnty.logo_url || '')
    const [taxyear, setTaxYear] = useState(props.cnty.taxyr)
    const [isUsed, setIsUsed] = useState(props.cnty.isused)
    const { keycloak, initialized } = useKeycloak()

    const handleSave = () => {
        let newCounty = {
            id: props.cnty.id,
            logo_url: logoUrl,
            map_link: mapLink,
            show_mapcard: showMapCard,
            show_photos: showPhotos,
            taxyr: taxyear,
            isused: isUsed
        }

        updateCounty(newCounty).then((response) => {
            console.log(response)

            toast.success('County Updated')

        }).catch((error) => {
            console.log(error)
            toast.error('Error Updating County')
        })

        //console.log(JSON.stringify(newCounty))
    }

    return (
        <div>
            {keycloak.authenticated &&
                <FormControl>
                    <TextField
                        sx={{ minWidth: '1600px' }}
                        value={mapLink}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setMapLink(event.target.value)
                        }}
                    />
                    <TextField
                        value={logoUrl}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setLogoUrl(event.target.value)
                        }}
                    />
                    <TextField
                        value={taxyear}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setTaxYear(+event.target.value)
                        }}
                    />
                    <FormControlLabel
                        label='Used in Portal'
                        control={
                            <Checkbox
                                checked={isUsed}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setIsUsed(event.target.checked)
                                }}
                                value={props.cnty.jur}
                            />
                        }></FormControlLabel>
                    <FormControlLabel
                        label='Show Map Cards'
                        control={
                            <Checkbox
                                checked={showMapCard}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setShowMapCard(event.target.checked)
                                }}
                                value={props.cnty.jur}
                            />
                        }></FormControlLabel>
                    <FormControlLabel
                        label='Show Photos'
                        control={
                            <Checkbox
                                checked={showPhotos}
                                value={props.cnty.jur}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setShowPhotos(event.target.checked)
                                }}
                            />
                        }></FormControlLabel>
                    <Button sx={{ maxWidth: '200px' }} onClick={handleSave} variant="contained">Save</Button>
                </FormControl>
            }

        </div>
    )
}