import { Card, CardContent, CardHeader, Grid } from "@mui/material"
import MainLayout from "./mainLayoutView"
import packageJson from '../../package.json'

export const AboutView = () => {
    return (
        <MainLayout>
            <Card>
                <CardHeader>
                    About
                </CardHeader>
                <CardContent>
                    <h3>IASW Portal</h3>
                    <h4>Atlas Geographic Data, Inc.</h4>
                    <table>
                        <tbody>
                            <tr>
                                <td>Version:</td><td>{packageJson.version}</td>
                            </tr>
                            <tr>
                                <td>Contact:</td><td><a href="mailto:it.support@atlasgeodata.com">it.suport@atlasgeodata.com</a></td>
                            </tr>
                        </tbody>
                    </table>

                </CardContent>
            </Card>
        </MainLayout>
    )
}