import '../../App.css';
import { useWindowDimensions, useMapCardModal } from '../../app/hooks';
import { fetchPhotos } from './mapcardsAPI';
import Box from '@mui/material/Box';

import {
    MapCardItem
} from './mapcardSlice'

import { IconButton, ImageList, ImageListItem, ImageListItemBar, Modal, Button } from '@mui/material';
import { useState, useEffect } from 'react'
import { PhotoSizeSelectActual } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import 'react-medium-image-zoom/dist/styles.css'


const style = {
    position: 'absolute' as 'absolute',
    width: '100%',
    height: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const imgStyle = {
    position: 'relative' as 'relative',
    ObjectFit: 'cover',
}


export interface MapcardProps {
    countyName: string | undefined,
    parid: string | undefined
}



export function Mapcards(props: MapcardProps) {

    const [photoList, setPhotoList] = useState(Array<MapCardItem>);
    const { height, width } = useWindowDimensions();

    function doQuery() {
        if (props.countyName !== undefined && props.parid !== undefined) {
            fetchPhotos(props.countyName, props.parid).then(photoResult => {
                setPhotoList(photoResult.data);
            }).catch(() => {
                return;
            });
        }
    }

    const imgWidth = Math.floor(width / 2);
    const cols = width > 900 ? 2 : 1;
    const isDesktop = width >= 968;

    const { isShowing, open, close, selected } = useMapCardModal();

    useEffect(() => {
        doQuery();
    }, [props.countyName, props.parid]);

    return (
        <div>
            <ImageList cols={cols}>
                {photoList.map(py => {
                    return <ImageListItem key={`${py.id}`}>
                        <img src={`${py.url}`} loading="lazy" onClick={() => open(py)} />
                        {isDesktop &&
                            <ImageListItemBar onClick={() => open(py)} title={`${py.name}`} position="top" actionIcon={
                                <IconButton>
                                    <PhotoSizeSelectActual sx={{ color: `white` }} />
                                </IconButton>
                            } />}
                    </ImageListItem>
                })}
            </ImageList>
            <Modal
                open={isShowing}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Button style={{ float: 'right' }} onClick={close}><CloseIcon /></Button>
                    <div style={{ height: '90%', width: '90%' }}>
                    <ReactPanZoom
                        image={`${selected?.url}`}
                        alt={selected?.name}
                    />
                    </div>
                </Box>
            </Modal>

        </div>
    )
}