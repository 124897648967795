import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { fetchDistricts, fetchMaps, fetchParcels, ApiResult } from './dmpAPI';

export interface DMPState {
  taxdistrict: string;
  taxDistricts: Array<string>,
  taxmap: string;
  taxMaps: Array<string>,
  taxparcel: string;
  taxParcels: Array<string>;
}


const initialState: DMPState = {
    taxdistrict: '',
    taxmap: '',
    taxparcel: '',
    taxDistricts: [],
    taxMaps: [],
    taxParcels: []
  };

export const DMPSlice = createSlice({
    name: 'DMP',
    initialState,
    reducers: {
        setDistrict: (state, action: PayloadAction<string>) => {
            state.taxdistrict = action.payload;
        },
        setMap: (state, action: PayloadAction<string>) => {
            state.taxmap = action.payload;
        },
        setParcel: (state, action: PayloadAction<string>) => {
            state.taxparcel = action.payload;
        },
        setDistricts: (state, action: PayloadAction<ApiResult>) => {
            state.taxDistricts = action.payload.data;
        },
        setParcels: (state, action: PayloadAction<ApiResult>)=> {
            state.taxParcels = action.payload.data;
        },
        setMaps: (state, action: PayloadAction<ApiResult>) => {
            state.taxMaps = action.payload.data;
        }
    }
});


export const { setDistrict, setMap, setParcel, setDistricts, setMaps, setParcels } = DMPSlice.actions;


export const getDistrictsAsync =
  (): AppThunk =>
  (dispatch, getState) => {
    let taxyr = getState().county.taxYear;
    let jur = getState().county.countyName;
    fetchDistricts(jur,taxyr).then(data=>{
        dispatch(setDistricts(data));
    })
  };

  export const getMapsAsync =
  (district: string): AppThunk =>
  (dispatch, getState) => {
    let taxyr = getState().county.taxYear;
    let jur = getState().county.countyName;
    fetchMaps(jur,taxyr,district).then(data=>{
        dispatch(setMaps(data));
    })
  };

  export const getParcelsAsync =
  (taxmap: string): AppThunk =>
  (dispatch, getState) => {
    let taxyr = getState().county.taxYear;
    let jur = getState().county.countyName;
    let district = getState().dmp.taxdistrict;
    
    fetchParcels(jur,taxyr,district, taxmap).then(data=>{
        dispatch(setParcels(data));
    })
  };


  export default DMPSlice.reducer;