import '../../App.css';
import { useParams } from 'react-router-dom';
import { ParcelItem } from '../parcel/parcelSlice';
import { useEffect, useState } from 'react';
import { fetchParcels } from './parcelsAPI'
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Paper, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { AttributeItem } from '../../components/attributeItem';
import { currencyFormatter } from '../../app/hooks';
import { Box } from '@mui/system';
import csvDownload from 'json-to-csv-export'



type parcelsProps = {
    where: string,
    variant?: 'parcel' | 'owner' | 'dwelling',
    limit?: number,
    orderBy?: string
}


export function Parcels({ where = '', variant = 'owner', limit = 10, orderBy = '' }: parcelsProps) {
    const { countyName, taxYear } = useParams()

    const [parcelList, setParcels] = useState(Array<ParcelItem>);
    const [parcelWhere, setWhere] = useState('');
    const [nextPageId, setNextPageId] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0)


    function doQuery(where: string, orderBy: string) {
        if (countyName !== undefined && taxYear !== undefined && where !== undefined && where.length > 0) {
            fetchParcels(countyName!, +taxYear, where!, limit, orderBy, 0).then(results => {
                setTotalRecords(results.totalRecords)
                setParcels(results!.data);
                setNextPageId(results!.next_page_id!);
                setHasMore(results.totalRecords > results.length)
            });
        }
    }

    function doQueryLM(where: string, orderBy: string) {
        if (countyName !== undefined && taxYear !== undefined && where !== undefined && where.length > 0) {
            fetchParcels(countyName!, +taxYear, where!, limit, orderBy, nextPageId).then(results => {
                setParcels([...parcelList, ...results!.data]);
                setNextPageId(results!.next_page_id!);
                setHasMore(parcelList.length < totalRecords)
            });
        }
    }

    function handleLoadMore() {
        doQueryLM(where, orderBy)
    }

    useEffect(() => {
        setTotalRecords(0)
        if (where === null || where === undefined || where.length < 5) {
            var empty = new Array<ParcelItem>();
            setParcels(empty)
        } else {
            if (where !== parcelWhere) {
                var empty = new Array<ParcelItem>();
                setParcels(empty)
                doQuery(where, orderBy)
            } else {
                doQuery(where, orderBy)
            }

        }
        setWhere(where)
    }, [where, orderBy]);

    return (
        <Box>
            <Typography color={'text.secondary'} width={'100%'} align="center">
                {parcelList?.length} Results {totalRecords > 0 && ` of ${totalRecords}`}
            </Typography>
            {parcelList.map(pi => {
                return (
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} key={`summary-${pi.objectid}`} id={`panel-${pi.objectid}-header`} aria-controls={`panel-${pi.objectid}-content`}>
                            <Grid container columns={{ xs: 1, sm: 4, md: 8 }} rowSpacing={2} columnSpacing={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={1} sm={2} md={4}>
                                    <Typography sx={{ width: '50%' }}>
                                        {pi.parid} ({pi.dmp})
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <Typography sx={{ color: 'text.secondary', }}>
                                        {pi.own1}    {pi.own2}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </AccordionSummary>
                        <AccordionDetails key={`detail-${pi.objectid}`}>
                            <Grid container columns={{ xs: 1, sm: 4, md: 8 }} rowSpacing={2} columnSpacing={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Parid" value={pi.parid} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Own1" value={pi.own1} />
                                </Grid>
                                {variant === 'owner' && <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Owner Addr." value={pi.owneraddr} />
                                </Grid>
                                }
                                {variant === 'owner' && <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Own2" value={pi.own2} />
                                </Grid>
                                }
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Acres" value={pi.acres} />
                                </Grid>

                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Book/Page" value={`${pi.book}/${pi.page}`} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Prop Addr" value={pi.situsaddr} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="District" value={pi.districtname} />
                                </Grid>
                                {variant === 'parcel' &&
                                    <Grid item xs={1} sm={2} md={4}>
                                        {variant === 'parcel'} <AttributeItem name="Apr Total" value={currencyFormatter(pi.aprtot)} />
                                    </Grid>
                                }{variant === 'parcel' &&
                                    <Grid item xs={1} sm={2} md={4}>
                                        <AttributeItem name="Apr Land" value={currencyFormatter(pi.aprland)} />
                                    </Grid>
                                }{variant === 'parcel' &&
                                    <Grid item xs={1} sm={2} md={4}>
                                        <AttributeItem name="Apr Building" value={currencyFormatter(pi.aprbldg)} />
                                    </Grid>
                                }
                                <Grid item xs={1} sm={2} md={4}>
                                    <Button variant='contained' href={`/detail/${countyName}/${taxYear}/${pi.parid}`}>Details</Button>
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <Button variant='contained' href={`/prc/${countyName}/${taxYear}/${pi.parid}`}>PRC</Button>
                                </Grid>

                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                )
            })}
              <Grid container columns={{ xs: 1, sm: 4, md: 8 }} rowSpacing={2} columnSpacing={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item sx={{minWidth:'40%'}}>
                    {parcelList.length > 0 && 
                        <Button variant='contained' onClick={()=>{
                            csvDownload({data:parcelList, filename:`${countyName}_ParcelData.csv`,delimiter:','})
                        }}>Download Data</Button>
                    }
                </Grid>
                <Grid item sx={{minWidth:'40%', float: 'right'}}>
                    {hasMore && <Button variant='contained' onClick={handleLoadMore} >Load More</Button>}
                </Grid>
            </Grid>
            
        </Box>
    )
}