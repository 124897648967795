import '../../App.css'
import './PRC.css'
import { useParams } from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';
import { useAppDispatch, dmp2Parid, currencyFormatter, dateFormatter } from '../../app/hooks';
import { useEffect, useState } from 'react';
import { Backdrop, Box, Button, CircularProgress, ImageList, ImageListItem, Table } from '@mui/material';
import { ParcelItem, setApiResult, CardsData, PhotoItem, SaleItem, MapOptItem, AsmtItem, MapCardItem } from './prcSlice';
import { fetchPRCData } from './prcAPI';
import { Sketch } from '../sketch/Sketch';
import moment from 'moment';



const HeaderStyle = {
    top: 0,
    left: 0,
    right: 0,
    height: '1in',
}





export function PRC() {

    const dispatch = useAppDispatch()

    const { countyName, taxYear, parid } = useParams()


    const [isloading, setIsLoading] = useState(true);
    const parid1 = dmp2Parid(`${parid}`);
    const taxYear1 = taxYear === undefined ? 0 : +taxYear;
    const countyName1 = countyName === undefined ? "" : countyName;
    const [PrcCards, setPrcCards] = useState<Array<CardsData>>({} as Array<CardsData>);
    const [photos, setPhotos] = useState<Array<PhotoItem>>();
    const [mapCards, setMapCards] = useState<Array<MapCardItem>>();
    const [lastSale, setLastSale] = useState<SaleItem>()
    const [mapOpts, setMapOpts] = useState<MapOptItem>();
    const [noCard, setNoCard] = useState(false)
    const [usePhotos, setUsePhotos] = useState(false);
    const [prcPhotoPage, setPrcPhotoPage] = useState(true);
    const [mapCard, setMapCard] = useState<MapCardItem>();

    const [parcel, setParcel] = useState<ParcelItem>();

    const [asmt, setAsmt] = useState<AsmtItem>();


    //const PrcCards = useAppSelector(selectCards);


    function SketchImageWidth() {
        try {
            var rect = document.getElementsByClassName('sketch-area')[0].getBoundingClientRect();
            return rect.width;
        } catch {
            return 200;
        }
    }


    useEffect(() => {

        fetchPRCData(countyName1, taxYear1, parid1).then(tables => {
            if (tables.data && tables.data.length > 0) {
                setParcel(tables.data[0].parcel)
                dispatch(setApiResult(tables));
                setPrcCards(tables.data);


                if (tables.asmt && tables.asmt.length > 0) {
                    setAsmt(tables.asmt[0]);
                }


                if (tables.mapOpts && tables.mapOpts.length > 0) {
                    setMapOpts(tables.mapOpts[0])
                    if (tables.mapOpts[0].show_photos) {
                        let imgPhotos: Array<PhotoItem> = [];

                        tables.photos.map(photo => {
                            if (photo.taxyr !== 999 && photo.taxyr !== 1200) {
                                imgPhotos.push(photo);
                            }
                        })
                        setPhotos(imgPhotos)
                        if (imgPhotos.length > 0) {
                            setUsePhotos(true)
                        } else {
                            setUsePhotos(false)
                        }
                        if (tables.mapOpts[0]?.options?.prc_extra_pictures == false) {
                            setPrcPhotoPage(false)
                        }


                    } else {
                        setUsePhotos(false)
                    }
                    if (tables.mapOpts[0].show_mapcard) {
                        if (tables.mapcards && tables.mapcards.length > 0) {
                            setMapCards(tables.mapcards)
                        }
                        let xmapCard = tables.mapcards[0]
                        if (xmapCard !== null && xmapCard !== undefined) {
                            setMapCard(xmapCard)
                        }
                    }
                }

                var comDate = moment('1901-09-05T00:00:00Z')
                let latestSale = undefined;
                tables.sales?.map(sale => {
                    let sdt = moment(sale.saledt) //2019-09-05T00:00:00Z
                    if (sdt > comDate) {
                        latestSale = sale;
                        comDate = sdt;
                    }
                });
                setLastSale(latestSale);
                setNoCard(false)
            } else {
                setNoCard(true)
            }
            setIsLoading(false)
        });

    }, [countyName1, parid1, taxYear1]);

    return (
        <div className='page-align'>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isloading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {PrcCards.length &&
                <div>
                    <div className='prc-button-header'>
                        <Button className='print-btn' variant='contained' onClick={() => { window.print(); }} endIcon={<PrintIcon />}>Print</Button>
                    </div>
                    {PrcCards!.map(thisCard => {
                        return (
                            <div>
                                <div className='prc-page'>
                                    <div className='prc-taxyr'>
                                        <span>Tax Year: {thisCard.parcel.taxyr}</span>
                                    </div>
                                    <div className='prc-logo'>
                                        {mapOpts?.logo_url &&
                                            <img src={mapOpts.logo_url} alt="logo" />
                                        }
                                    </div>
                                    <div style={HeaderStyle}>
                                        <h4 className='header-text' color={'text.secondary'}>Property Record Card</h4>

                                        <h4>Parid: {thisCard?.parcel?.parid} -|- {thisCard.parcel.dmp}</h4>
                                        <br />
                                        <div className='cards-group' >
                                            <h4>Card: {thisCard.card}</h4>
                                        </div>
                                    </div>
                                    <Table className='prc-table'>
                                        <tbody>
                                            <tr className='prc-row'>

                                            </tr>
                                            <tr className='prc-row'>
                                                <td className='prc-key'>Situs Addr.</td>
                                                <td className='prc-value'>{thisCard.parcel.situsaddr}</td>
                                                <td className='prc-key'>District</td>
                                                <td className='prc-value'>{thisCard.parcel.districtname}</td>
                                                <td className='prc-key'>Map</td>
                                                <td className='prc-value'>{thisCard.parcel.taxmap}</td>
                                            </tr>
                                            <tr className='prc-row'>
                                                <td className='prc-key'>Owner1</td>
                                                <td className='prc-value'>{thisCard.parcel.own1}</td>
                                                <td className='prc-key'>Owner2</td>
                                                <td className='prc-value'>{thisCard.parcel.own2}</td>
                                                <td className='prc-key'>Owner Addr</td>
                                                <td className='prc-value'>{thisCard.parcel.owneraddr} {thisCard.parcel.cityname}, {thisCard.parcel.statecode} {thisCard.parcel.zip1}</td>

                                            </tr>
                                            <tr className='prc-row'>
                                                <td className='prc-key'>Deed Book/Page</td>
                                                <td className='prc-value'>{thisCard.parcel.book}/{thisCard.parcel.page}</td>
                                                <td className='prc-key'>Land Use</td>
                                                <td className='prc-value'>{thisCard.parcel.luc}</td>
                                                <td className='prc-key'>Acreage</td>
                                                <td className='prc-value'>{thisCard.parcel.acres}</td>
                                            </tr>
                                            <tr className='prc-row'>
                                                <td className='prc-key'>Appraised Blg Val</td>
                                                <td className='prc-value'>{currencyFormatter(thisCard.parcel.aprbldg)}</td>
                                                <td className='prc-key'>Appraised Land Val</td>
                                                <td className='prc-value'>{currencyFormatter(thisCard.parcel.aprland)}</td>
                                                <td className='prc-key'>Total Appraised Val</td>
                                                <td className='prc-value'>{currencyFormatter(thisCard.parcel.aprtot)}</td>
                                            </tr>
                                            <tr className='prc-row'>
                                                <td className='prc-key'>Assessed Blg Val</td>
                                                <td className='prc-value'>{currencyFormatter(asmt?.totasm2!)}</td>
                                                <td className='prc-key'>Assessed Land Val</td>
                                                <td className='prc-value'>{currencyFormatter(asmt?.totasm1!)}</td>
                                                <td className='prc-key'>Total Assessed Val</td>
                                                <td className='prc-value'>{currencyFormatter(asmt?.totasm1! + asmt?.totasm2!)}</td>
                                            </tr>
                                            <tr className='prc-row'>
                                                <td className='prc-key'>Year Built</td>
                                                <td className='prc-value'>{thisCard?.dwellings[0]?.yrblt}</td>
                                                <td className='prc-key'>Total Rooms</td>
                                                <td className='prc-value'>{thisCard?.dwellings[0]?.rmtot}</td>
                                                <td className='prc-key'>SqFt</td>
                                                {thisCard.comdats[0] && 
                                                    <td className='prc-value'>{thisCard?.comdats[0]?.busla}</td>
                                                }
                                                {thisCard.dwellings[0] && 
                                                    <td className='prc-value'>{thisCard?.dwellings[0]?.sfla}</td>
                                                }
                                                <td className='prc-key'>Bedrooms</td>
                                                <td className='prc-value'>{thisCard?.dwellings[0]?.rmbed}</td>
                                            </tr>
                                            <tr className='prc-row'>
                                                <td className='prc-key'>Bathrooms</td>
                                                <td className='prc-value'>{thisCard?.dwellings[0]?.fixbath}</td>
                                                <td className='prc-key'>Half Baths</td>
                                                <td className='prc-value'>{thisCard?.dwellings[0]?.fixhalf}</td>
                                                <td className='prc-key'>Total Fixtures</td>
                                                <td className='prc-value'>{thisCard?.dwellings[0]?.fixtot}</td>
                                            </tr>
                                            <tr className='prc-row'>
                                                <td className='prc-key'>Basement Area</td>
                                                <td className='prc-value'>{thisCard?.dwellings[0]?.finbsmtarea}</td>
                                                <td className='prc-key'>Recroom Area</td>
                                                <td className='prc-value'>{thisCard?.dwellings[0]?.recromarea}</td>
                                                <td className='prc-key'>Heat</td>
                                                <td className='prc-value'>{thisCard?.dwellings[0]?.heattype}</td>
                                            </tr>
                                            <tr className='prc-row'>
                                                <td className='prc-key'>Tax Class</td>
                                                <td className='prc-value'>{thisCard?.parcel.class}</td>
                                                <td className='prc-key'>Neighborhood</td>
                                                <td className='prc-value'>{thisCard.parcel.nbhd}</td>
                                                <td className='prc-key'>Fuel</td>
                                                <td className='prc-value'>{thisCard?.dwellings[0]?.fueltype}</td>
                                            </tr>
                                            <tr className='prc-row'>
                                                <td className='prc-key'>Legal Desc 1</td>
                                                <td className='prc-value'>{thisCard?.parcel.legal1}</td>
                                                <td className='prc-key'>Legal Desc 2</td>
                                                <td className='prc-value'>{thisCard?.parcel.legal2}</td>
                                                <td className='prc-key'>Legal Desc 3</td>
                                                <td className='prc-value'>{thisCard?.parcel.legal3}</td>
                                            </tr>
                                            <tr className='prc-row'>
                                                <td className='prc-key'>Sale Date</td>
                                                <td className='prc-value'>
                                                    {lastSale?.saledt &&
                                                        dateFormatter(lastSale?.saledt)
                                                    }
                                                </td>
                                                <td className='prc-key'>Sale Price</td>
                                                <td className='prc-value'>
                                                    {lastSale?.price &&
                                                        currencyFormatter(lastSale?.price)
                                                    }
                                                </td>
                                                <td className='prc-key'>Sale Book/Page</td>
                                                <td className='prc-value'>
                                                    {lastSale?.book!}/{lastSale?.page!}
                                                </td>
                                            </tr>
                                            <tr className='prc-row'>

                                            </tr>
                                            <tr className='prc-row'>

                                            </tr>

                                        </tbody>
                                    </Table>
                                    <div className='sketch-row'>
                                        <div className='sketch-area'>
                                            {thisCard.addns.length > 0 &&
                                                <Sketch parid={thisCard.parcel.parid} county={countyName} taxyr={taxYear} card={thisCard.card} />
                                            }
                                        </div>
                                        <div className='sketch-area'>
                                            {thisCard.addns.length > 0 &&
                                                <Table className='prc-table'>
                                                    <thead>
                                                        <tr className='prc-row'>
                                                            <th className='prc-sketch-column'>Part</th>
                                                            <th className='prc-sketch-column'>Lower</th>
                                                            <th className='prc-sketch-column'>First</th>
                                                            <th className='prc-sketch-column'>Second</th>
                                                            <th className='prc-sketch-column'>Third</th>
                                                            <th className='prc-sketch-column'>Area</th>
                                                        </tr>
                                                    </thead>
                                                    {thisCard.addns &&
                                                        <tbody>
                                                            {thisCard.addns.map(addn => {
                                                                return (
                                                                    <tr className='prc-row'>
                                                                        <td style={{ backgroundColor: `${addn.color}` }} className='prc-sketch-column'>{addn.name}</td>
                                                                        <td className='prc-sketch-column'>{addn.lower}</td>
                                                                        <td className='prc-sketch-column'>{addn.first}</td>
                                                                        <td className='prc-sketch-column'>{addn.second}</td>
                                                                        <td className='prc-sketch-column'>{addn.third}</td>
                                                                        <td className='prc-sketch-column'>{addn.area}</td>
                                                                    </tr>
                                                                )
                                                            })}

                                                            <tr></tr>
                                                        </tbody>
                                                    }
                                                </Table>
                                            }
                                        </div>
                                        {usePhotos &&
                                            <div className='sketch-area'>
                                                <img src={`${photos![0].url}?width=${SketchImageWidth()})`} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                                            </div>
                                        }

                                    </div>
                                </div>
                                {mapCard &&
                                    <div className='prc-page-2'>
                                        <img style={{ maxWidth: '10in' }} src={mapCard.url} />
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
            }
            {noCard &&
                <Box>
                    <h3>No Data Available</h3>
                </Box>
            }
            {prcPhotoPage &&
                <div className='prc-page-3'>
                    {usePhotos &&
                        <ImageList>
                            {photos!.map(photo => {
                                return (
                                    <ImageListItem>
                                        <img src={photo.url} />
                                    </ImageListItem>
                                )
                            })}
                        </ImageList>
                    }

                </div>
            }
        </div>
    )
}
