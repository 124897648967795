import { API_BASE_URL, API_KEY } from "../../app/constants";


export const fetchNbhds = (jur: string, taxyr: number) => {
    let url = `${API_BASE_URL}/query/${jur}/${taxyr}/parcel?returnFields=distinct nbhd&apiKey=${API_KEY}`

    return new Promise<Array<string>>((resolve, reject) => {
        fetch(url)
            .then(response => {
                response.json()
                    .then(json => {
                        if (json.status === "OK") {
                            var b = json.data.map((obj: { nbhd: any; }) => {
                                return obj.nbhd
                            })
                            resolve(b)
                        } else {
                            resolve([])
                        }
                    }).catch(err => {
                        resolve([])
                    })
            })
            .catch(err => {
                resolve([])
            })
    })

}