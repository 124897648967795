import '../../App.css';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { fetchDwellings } from './dwellingsAPI'
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Paper, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { AttributeItem } from '../../components/attributeItem';
import { currencyFormatter } from '../../app/hooks';
import { Box } from '@mui/system';
import csvDownload from 'json-to-csv-export'
import { DwellingItem } from './dwellingsSlice';



type dwellingsProps = {
    where: string,
    variant?: 'dwelling' | 'owner' | 'dwelling',
    limit?: number,
    orderBy?: string
}

const KeyValue = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    width: '90%',
    height: 'auto'
}));

const ItemName = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));
const ItemValue = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'right',
    color: theme.palette.text.primary,
}));


export function Dwellings({ where = '', variant = 'owner', limit = 10, orderBy = '' }: dwellingsProps) {
    const { countyName, taxYear } = useParams()

    const [dwellingList, setDwellings] = useState(Array<DwellingItem>);
    const [dwellingWhere, setWhere] = useState('');
    const [nextPageId, setNextPageId] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0)


    function doQuery(where: string, orderBy: string) {
        if (countyName !== undefined && taxYear !== undefined && where !== undefined && where.length > 0) {
            fetchDwellings(countyName!, +taxYear, where!, limit, orderBy, 0).then(results => {
                setTotalRecords(results.totalRecords)
                setDwellings(results!.data);
                setNextPageId(results!.next_page_id!);
                setHasMore(results.totalRecords > results.length)
            });
        }
    }

    function doQueryLM(where: string, orderBy: string) {
        if (countyName !== undefined && taxYear !== undefined && where !== undefined && where.length > 0) {
            fetchDwellings(countyName!, +taxYear, where!, limit, orderBy, nextPageId).then(results => {
                setDwellings([...dwellingList, ...results!.data]);
                setNextPageId(results!.next_page_id!);
                setHasMore(dwellingList.length < totalRecords)
            });
        }
    }

    function handleLoadMore() {
        doQueryLM(where, orderBy)
    }

    useEffect(() => {
        setTotalRecords(0)
        if (where === null || where === undefined || where.length < 5) {
            setDwellings(new Array<DwellingItem>())
        } else {
            if (where !== dwellingWhere) {
                setDwellings(new Array<DwellingItem>())
                doQuery(where, orderBy)
            } else {
                doQuery(where, orderBy)
            }

        }
        setWhere(where)
    }, [where, orderBy, dwellingWhere]);

    return (
        <Box>
            <Typography color={'text.secondary'} width={'100%'} align="center">
                {dwellingList?.length} Results {totalRecords > 0 && ` of ${totalRecords}`}
            </Typography>
            {dwellingList.map(pi => {
                return (
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} key={`summary-${pi.objectid}`} id={`panel-${pi.objectid}-header`} aria-controls={`panel-${pi.objectid}-content`}>
                            <Grid container columns={{ xs: 1, sm: 4, md: 8 }} rowSpacing={2} columnSpacing={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={1} sm={2} md={4}>
                                    <Typography sx={{ width: '50%' }}>
                                        {pi.parid} ({pi.dmp})
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <Typography sx={{ color: 'text.secondary', }}>
                                        {`${pi.sfla} sqft ${pi.bldgstyle} ${pi.rmbed} br ${pi.fixbath} bt`}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </AccordionSummary>
                        <AccordionDetails key={`detail-${pi.objectid}`}>
                            <Grid container columns={{ xs: 1, sm: 4, md: 8 }} rowSpacing={2} columnSpacing={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Parid" value={pi.parid} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Own1" value={pi.own1} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Addr." value={pi.situsaddr} />
                                </Grid>

                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="SqFT" value={pi.sfla} />
                                </Grid>

                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Bedrooms" value={pi.rmbed} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Bathrooms" value={pi.fixbath} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Half Bath" value={pi.fixhalf} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Basement" value={`${pi.finbsmtarea} ${pi.bsmttype}`} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Attic" value={pi.attictype} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Exterior" value={pi.exterior} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Style" value={pi.bldgstyle} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Heat" value={`${pi.heattype} ${pi.heatsystem}`} />
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <AttributeItem name="Apr Building" value={currencyFormatter(pi.aprbldg)} />
                                </Grid>

                                <Grid item xs={1} sm={2} md={4}>
                                    <Button variant='contained' href={`/detail/${countyName}/${taxYear}/${pi.parid}`}>Details</Button>
                                </Grid>
                                <Grid item xs={1} sm={2} md={4}>
                                    <Button variant='contained' href={`/prc/${countyName}/${taxYear}/${pi.parid}`}>PRC</Button>
                                </Grid>

                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                )
            })}
            <Grid container columns={{ xs: 1, sm: 4, md: 8 }} rowSpacing={2} columnSpacing={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item sx={{ minWidth: '40%' }}>
                    {dwellingList.length > 0 &&
                        <Button variant='contained' onClick={() => {
                            csvDownload({ data: dwellingList, filename: `${countyName}_ParcelData.csv`, delimiter: ',' })
                        }}>Download Data</Button>
                    }
                </Grid>
                <Grid item sx={{ minWidth: '40%', float: 'right' }}>
                    {hasMore && <Button variant='contained' onClick={handleLoadMore} >Load More</Button>}
                </Grid>
            </Grid>

        </Box>
    )
}