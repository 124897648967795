import { InputLabel, NativeSelect } from '@mui/material'
import { useState, useEffect } from 'react'
import { Box } from '@mui/system'
import React from 'react';
import { DistrictItem } from './districtsSlice';
import { fetchDistrict } from './districtsAPI';


export interface DistrictSelectProps {
    jur: string,
    taxyr: any,
    onSelection: (value: string) => void
}


export const DistrictSelect = (props: DistrictSelectProps) => {

    const [codes, setCodes] = useState([] as Array<DistrictItem>)
    const [selcode, setSelCode] = useState('')



    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelCode(event.target.value as string);
        props.onSelection(event.target.value as string);
    };

    useEffect(() => {
        fetchDistrict(props.jur, props.taxyr).then(data => {
            let tmp = [] as Array<DistrictItem>;
            data.data.forEach(dist => {
                if (dist.display != null && dist.display != undefined) {
                    tmp.push(dist)
                }
            });
            setCodes(tmp);
        });
    }, [props.jur, props.taxyr]);

    return (
        <Box>
            <InputLabel htmlFor="district_select">District</InputLabel>
            <NativeSelect
                value={selcode}
                defaultValue={""}
                inputProps={{
                    name: "District",
                    id: "district_select"
                }}
                onChange={handleChange}
            >

                <option value={""}>None</option>
                {codes!.map(cd => {
                    return (
                        <option value={cd.code}>{cd.display}</option>
                    )
                })}
            </NativeSelect>
        </Box>
    )

}