import '../App.css';
import MainLayout from './mainLayoutView';
import CountiesAutocomplete from '../features/county/mainLayout';
import { API_BASE_URL } from '../app/constants';
//import { useAppSelector } from '../app/hooks';
//import {
//  selectName,
//  selectTaxYear
//} from '../features/county/countySlice';
import { Box } from '@mui/material';

export function Home() {
  //const countyName = useAppSelector(selectName);
  //const taxYear = useAppSelector(selectTaxYear);
  return (
    <MainLayout>
      <header className="App-header">
        <Box>
        <h3>Select County and Year</h3>
        <CountiesAutocomplete {...{ url: `${API_BASE_URL}/counties` }} />
        </Box>
      </header>
    </MainLayout>
  )
}
