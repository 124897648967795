
import { API_BASE_URL, API_KEY } from "../../app/constants";



export const fetchBook = (countyName: string, taxYear: number, deedBook: string) => {
    let url = `${API_BASE_URL}/typeahead/${countyName}/${taxYear}/parcel/book?find=${deedBook}&apiKey=${API_KEY}`;


    return new Promise<Array<string>>((resolve, reject) => {
        fetch(url, { method: "GET" })
            .then((response) => response.json())
            .catch(err => { resolve([]) })
            .then((json) => {
                if (json.status === "OK") {
                    var b = json.data.map((obj: { val: any; }) => {
                        return obj.val
                    });
                    resolve(b);
                }
            }).catch(err => { resolve([]) });
    });
}

export const fetchPage = (countyName: string, taxYear: number, deedBook: string, deedPage: string) => {

    let url = `${API_BASE_URL}/query/${countyName}/${taxYear}/parcel?apiKey=${API_KEY}&returnFields=distinct page as val`;
    if (deedBook != null && deedBook !== undefined && deedBook !== "") {
        url += `&where=book lk ${deedBook} and page lk ${deedPage}`;
    } else {
        url += `&where=page lk ${deedPage}`;
    }

    return new Promise<Array<string>>((resolve, reject) => {
        fetch(url, { method: "GET" })
            .then((response) => response.json())
            .catch(err => { resolve([]) })
            .then((json) => {
                if (json.status === "OK") {
                    var b = json.data.map((obj: { val: any; }) => {
                        return obj.val
                    })
                    resolve(b);
                }
            }).catch(err => {
                resolve([])
            });
    });


}