import { Autocomplete, TextField, AutocompleteInputChangeReason, AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material'
import { Box } from "@mui/system"
import { useEffect, useState } from "react"
import { fetchNbhds } from "./NbhdAPI"


export interface NbhdSearchProps {
    jur: string,
    taxyr: number,
    nbhdChanged: (value: string) => void
}

export const NbhdSearch = (props: NbhdSearchProps) => {

    const [nbhdResults, setNbhdResults] = useState(['']);
    const [nbhd, setNbhd] = useState('')



    const handleChange = (event: React.SyntheticEvent,
        value: any,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails) => {

        props.nbhdChanged(value);
        setNbhd(value);
    }
    const handleIputChange = (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
        if (reason === 'clear' || reason === 'reset') {
            setNbhd('')
        }
        props.nbhdChanged(value);
    }

    useEffect(() => {

        fetchNbhds(props.jur, props.taxyr).then(data => {
            setNbhdResults(data);
        })
    }, [props.jur, props.taxyr])

    return (
        <Autocomplete
            id='ias_autocomplete_nbhd'
            getOptionLabel={(nbhdResults) => nbhdResults}
            options={nbhdResults}
            sx={{ width: 150 }}
            isOptionEqualToValue={(option, value) => option === value}
            noOptionsText={"No results found"}
            renderOption={(props, nbhdResults) => (
                <Box component={'li'} {...props}>
                    {nbhdResults}
                </Box>
            )}
            renderInput={(params) => <TextField {...params} label=" Neighborhood" />}
            onChange={handleChange}
            value={nbhd}
            onInputChange={handleIputChange}
        />
    )
}